import React from "react";

const NamesFormatter = ({ value }: any) => {
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((name) => (
          <div key={name}>{name}</div>
        ))}
      </div>
    );
  } else {
    return value;
  }
};

export default NamesFormatter;
