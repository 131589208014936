import React from "react";
import { AlternateSubheader, AlternateHeader } from "../Header/Header";
import { AlternateTextBox } from "../TextBox/TextBox";
import { AlternateButton } from "../Button/Button";
import { TopSpacing } from "../Spacing/Spacing";

import { SHAREPOINT } from "../../../resources/Config";
import styles from "./Jumbotron.module.css";

type Props = {
  useSubheader: boolean;
};

const mcoeHeaderText = "Mobile & XR";
export function McoeJumbotron({ useSubheader = false }: Props) {
  const goToSharePoint = () => {
    window.open(SHAREPOINT, "_blank");
  };

  return (
    <div className={styles.mcoe}>
      <div className={styles.tile}>
        {useSubheader === true ? (
          <AlternateSubheader text={mcoeHeaderText} />
        ) : (
          <AlternateHeader text={mcoeHeaderText} />
        )}
        <AlternateTextBox>Other ways to connect</AlternateTextBox>
        <TopSpacing>
          <AlternateButton text="SharePoint" onClick={goToSharePoint} />
        </TopSpacing>
      </div>
    </div>
  );
}

export function GetInTouchJumbotron() {
  return (
    <div className={styles.getintouch}>
      <div className={styles.tile}>
        <AlternateHeader text="Get in touch with us" />
        <AlternateTextBox>Have a question or comment? Let us know</AlternateTextBox>
      </div>
    </div>
  );
}
