import axios from "axios";
import Auth from "volvo-ping-auth-helper";
import { baseUrl } from "../resources/Config";
import {
  DATA_LIST_OBTAINED,
  DATA_LIST_FILTERED,
  TOGGLE_SHOW_FILTERS,
} from "../resources/AppEvents";
import { Util } from "../resources/Util";
import AppManager from "../providers/AppManager";

export function initAuth(appManager: AppManager) {
  if (!Auth.initialized) {
    Auth.token_storage_key = "appPortal-AccessToken";
    Auth.after_logon_path_storage_key = "appPortal-AfterLogonGoTo";
    Auth.force_login = false;
    //Set this once reload token is added to the client: Auth.auto_reload_on_token_gained = false;
    Auth.auto_reload_on_logout = false;

    Auth.init({
      client_id: process.env.REACT_APP_CLIENT_ID || "",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL || "",
      auth_url: process.env.REACT_APP_AUTH_URL || "",
      logout_url: process.env.REACT_APP_LOGOUT_URL || "",
    });

    Auth.on("expired", () => {
      if (window.location.pathname !== baseUrl) {
        Auth.setPathToGoAfterLogon(window.location.pathname + window.location.search);
      }
    });

    Auth.on("error", (error) => {
      console.error(
        "Sorry but you are not allowed to login. Probably you are not part of the group.",
      );
    });

    Auth.on("login", () => {
      //login event means token data is valid and has been decoded in Auth object
      appManager.addEventListener(DATA_LIST_OBTAINED, () => {
        const path = Auth.getPathToGoAfterLogon();
        if (path) {
          Auth.clearPathToGoAfterLogon();

          if (path.indexOf("?") > -1) {
            //we have an address with a search query
            const trimedPath = path.substring(path.indexOf("?"));
            const filterSelection = Util.querySearchToObject(trimedPath);
            const showFilters = Object.keys(filterSelection).length ? true : false;

            if (showFilters) {
              appManager.triggerEvent(DATA_LIST_FILTERED, { filterSelection });
              appManager.triggerEvent(TOGGLE_SHOW_FILTERS, { showFilters });
            }
          }
        }

        appManager.goTo(path ? path : baseUrl, true);
      });
    });

    Auth.registerResponseInterceptor(axios.create());

    //test if not redirected from pingFederate with hash value
    //becuse when redirected then we will always have our desired address
    //overwritten by base url which is equal to /.
    if (!window.location.hash) {
      Auth.setPathToGoAfterLogon(window.location.pathname + window.location.search);
    }

    Auth.checkToken();
  }
}
