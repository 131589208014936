const baseColumns = [
  { field: "apmID", title: "APM ID", frozen: true, editable: false },
  { field: "names", title: "App Names", frozen: true, width: 400, editable: false },
  { field: "description", title: "Description" },
  { field: "budget2018", title: "Budget 2018" },
  { field: "actual2018", title: "Actual 2018" },
  { field: "budget2019", title: "Budget 2019" },
  { field: "actual2019", title: "Actual 2019" },
  { field: "budget2020", title: "Budget 2020" },
  { field: "actual2020", title: "Actual 2020" },
];

const columns = [
  { field: "name", title: "Name", frozen: true, editable: false },
  { field: "apmID", title: "APM ID", frozen: true },
  { field: "appStatus", title: "Status" },
  { field: "brand", title: "Brand" },
  { field: "bundleID", title: "iOS Bundle ID" },
  { field: "androidBundleID", title: "Android Bundle ID" },
  { field: "intuneBundleID", title: "InTune Bundle ID" },
  { field: "targetGroup", title: "Target Group" },
  { field: "platform", title: "Platform" },
  { field: "device", title: "Device" },
  { field: "region", title: "Region" },
  { field: "solutionLeader", title: "Solution Leader" },
  { field: "businessAnalyst", title: "BusinessAnalyst" },
  { field: "BSPM", title: "BSPM" },
  { field: "deliveryManager", title: "Delivery Manager" },
  { field: "domainManager", title: "Domain Manager" },
  { field: "teamLeader", title: "Delivery Leader" },
  { field: "businessKeyUser", title: "Business Key User" },
  { field: "businessProcess", title: "Business Process" },
  { field: "playStoreLink", title: "Play Store Link" },
  { field: "appleStoreLink", title: "Apple Store Link" },
  { field: "webLink", title: "Web Link" },
  { field: "videoLink", title: "VideoLink" },
  { field: "statusChangeDate", title: "Last time changed" },
  { field: "installIos", title: "Install iOS" },
  { field: "installAndroid", title: "Install Android" },
  { field: "installIosQA", title: "Install iOS - QA version" },
  { field: "installAndroidQA", title: "Install Android - QA version" },
  { field: "internalComments", title: "Internal Comments" },
  { field: "extraComments", title: "Extra Comments" },
  { field: "latestVersion", title: "iOS Latest Version" },
  { field: "androidLatestVersion", title: "Android Latest Version" },
  { field: "intuneLatestVersion", title: "InTune Latest Version" },
  { field: "changeDate", title: "iOS Change Date" },
  { field: "androidChangeDate", title: "Android Change Date" },
  { field: "intuneChangeDate", title: "InTune Change Date" },
];

export { baseColumns, columns };
