import React, { Component } from "react";
import axios from "axios";
import Auth from "volvo-ping-auth-helper";

import { appsDataUrl, budgetDataUrl, apiUrl, getAuthHeader } from "../../resources/Config";
import Grid from "./Grid";
import LoadingAlert from "./LoadingAlert";
import * as Format from "../../utils/Formats";

import "./index.css";
import { App } from "../../resources/Types";

type Props = {};
type State = {
  apps: App[];
  apmIds: string[];
  apmIdMode: boolean;
  message: string | undefined;
};

class Main extends Component<Props, State> {
  state = {
    apps: [],
    apmIds: [],
    apmIdMode: false,
    message: undefined,
  };

  getGroupingHeader = (budget: App) => {
    const budget2018 = `Budget 2018: ${Format.currencyFormat(budget.budget2018)}`;
    const actual2018 = `Actual 2018: ${Format.currencyFormat(budget.actual2018)}`;

    return budget.description !== "" ? `${budget.description} (${budget2018}, ${actual2018})` : "";
  };

  loadData = async (token: any) => {
    try {
      const loadDataPromises = [
        axios.post(
          appsDataUrl,
          {
            token,
          },
          { headers: getAuthHeader(token) },
        ),
        axios.get(budgetDataUrl, { headers: getAuthHeader(token) }),
      ];
      const [appsResponse, budgetsResponse] = await Promise.all(loadDataPromises);
      const { apps } = appsResponse.data;
      const { budgets } = budgetsResponse.data;

      budgets.sort((first: App, second: App) => {
        return first.apmID > second.apmID ? 1 : -1;
      });

      const apmIds = budgets.map((budget) => {
        const names = apps.filter((app) => app.apmID === budget.apmID).map((app) => app.name);

        return {
          ...budget,
          names,
        };
      });

      const enrichedApps = apps.map((app) => {
        const correspondingBudget = budgets.filter((budget) => budget.apmID === app.apmID);
        return {
          ...app,
          description:
            correspondingBudget.length > 0 ? this.getGroupingHeader(correspondingBudget[0]) : "",
        };
      });

      enrichedApps.sort((first, second) => {
        return first.description > second.description ? -1 : 1;
      });

      this.setState({
        apps: enrichedApps,
        apmIds,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  toggleMode = () => {
    this.setState({ apmIdMode: !this.state.apmIdMode });
  };

  saveChanges = async (data) => {
    const { apmIdMode } = this.state;
    const token = Auth.token;

    if (token) {
      const updatePromises = [];
      for (const portion of data) {
        if (apmIdMode === false) {
          updatePromises.push(
            axios.post(apiUrl, { app: JSON.stringify(portion) }, { headers: getAuthHeader(token) }),
          );
        } else {
          updatePromises.push(
            axios.put(budgetDataUrl, { budget: portion }, { headers: getAuthHeader(token) }),
          );
        }
      }

      this.setState({
        message: "Saving changes",
      });
      await Promise.all(updatePromises);
      await this.loadData(token);
      this.setState({
        message: undefined,
      });
    }
  };

  componentDidMount = async () => {
    const token = Auth.token;

    if (token) {
      this.loadData(token);
    }
  };

  render() {
    const { apps, apmIds, apmIdMode } = this.state;
    const data = apmIdMode === true ? apmIds : apps;
    return (
      <div className="pl-5 mr-2">
        <LoadingAlert message={this.state.message} />
        <Grid
          data={data}
          saveChanges={this.saveChanges}
          apmIdMode={apmIdMode}
          toggleMode={this.toggleMode}
        />
      </div>
    );
  }
}

export default Main;
