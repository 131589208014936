import React from "react";
import { Generic } from "fp-ui-components-react";
import { Util } from "../../../resources/Util";

import styles from "./Collapsible.module.css";

type Props = {
  title: string;
  parentFilter?: boolean;
  children: any;
};

const FA = Generic.FontAwesome;

export function Collapsible({ title, parentFilter = false, children }: Props) {
  const guid = Util.getRandString(16);

  return (
    <div className={styles.container}>
      <input type="checkbox" id={guid} />
      <label className={parentFilter === true ? styles.header : styles.subheader} htmlFor={guid}>
        <span>{title}</span>
        <span className={styles.chevron}>
          <FA name="chevron-down" />
        </span>
      </label>
      <div className={styles.content}>
        <div>{children}</div>
      </div>
    </div>
  );
}

export function SectionCollapsible({ title, children }: Props) {
  const guid = Util.getRandString(16);

  return (
    <div className={styles.sectionContainer}>
      <input type="checkbox" id={guid} />
      <label className={styles.sectionHeader} htmlFor={guid}>
        <span>{title}</span>
        <span className={styles.chevron}>
          <FA name="chevron-down" />
        </span>
      </label>
      <div className={styles.content}>
        <div>{children}</div>
      </div>
    </div>
  );
}
