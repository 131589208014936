import React from "react";
// @ts-ignore
import { Generic } from "fp-ui-components-react";
import "./LoadingAlert.css";
const FA = Generic.FontAwesome;

type Props = {
  message: any;
};

const LoadingAlert = ({ message }: Props) => {
  if (message) {
    return (
      <div className="overlay d-flex justify-content-center align-items-center">
        <div className="alert alert-secondary mt-2" role="alert">
          <FA name="spinner" className="d-inline-block" spin />
          <p className="d-inline-block ml-2">{message}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default LoadingAlert;
