import React, { Component } from "react";
import { Container, Row, Col, Input } from "reactstrap";
import { SmallActionButton, SmallActionSecondaryButton, AppPortalModal } from "../Common";
import { DevOpsRepo } from "../../resources/Types";
import cleanInputValue from "../../utils/Sanitization";

type State = {
  type: string;
  link: string;
};
type Props = {
  show: boolean;
  repoType: string;
  repoLink: string;
  handleSave: (repo: DevOpsRepo) => void;
  closeForm: () => void;
};

export default class AddRepoModal extends Component<Props, State> {
  state = {
    type: "",
    link: "",
  };

  componentDidMount = () => {
    this.setState({
      type: this.props.repoType,
      link: this.props.repoLink,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.repoType !== this.props.repoType || prevProps.repoLink !== this.props.repoLink) {
      this.setState({
        type: this.props.repoType,
        link: this.props.repoLink,
      });
    }
  };

  handlePropertyChange = (property: string) => (value: string) => {
    try {
      let cleanedValue = value;
      if (this.state[property].length < value.length) {
        cleanedValue = cleanInputValue(value);
      }

      if (cleanedValue.length > 0) {
        this.setState({ [property]: cleanedValue });
      }
    } catch (err) {
      console.log(err);
    }
  };

  clear = () => {
    this.setState({ type: "", link: "" });
  };

  handleClose = () => {
    this.clear();
    this.props.closeForm();
  };

  handleSaveNewRepo = () => {
    const { type, link } = this.state;
    this.props.handleSave({ type, link });
    this.clear();
  };

  formDataValid = () => {
    const { type, link } = this.state;
    return type !== "" && link !== "";
  };

  render() {
    return (
      <AppPortalModal title="Add new repo" size="lg" isOpen={this.props.show}>
        <Container>
          <Row>
            <Col lg="6" className="m-lg-auto">
              <Row>
                <Col>
                  <p>Repo type: </p>
                  <Input
                    type="select"
                    placeholder="provide type"
                    value={this.state.type}
                    onChange={(event) =>
                      this.handlePropertyChange("type")(event.currentTarget.value)
                    }
                  >
                    <option value="">----</option>
                    <option>Android</option>
                    <option>iOS</option>
                    <option>React Native</option>
                    <option>Unity</option>
                    <option>Web</option>
                    <option>Backend</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Repo link: </p>
                  <Input
                    type="text"
                    placeholder="provide link"
                    value={this.state.link}
                    onChange={(event) =>
                      this.handlePropertyChange("link")(event.currentTarget.value)
                    }
                  />
                </Col>
              </Row>

              <Row className="pt-3">
                <Col className="d-flex justify-content-between">
                  <SmallActionSecondaryButton text="Cancel" onClick={this.handleClose} />

                  <SmallActionButton
                    text="Confirm"
                    onClick={this.handleSaveNewRepo}
                    disabled={!this.formDataValid()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </AppPortalModal>
    );
  }
}
