import React from "react";
// @ts-ignore
import { ViewWrapper } from "airr-react";
import axios from "axios";
import { Generic, VolvoGroup } from "fp-ui-components-react";
import Auth from "volvo-ping-auth-helper";

import "../../css/LoginView.css";
import { User } from "../resources/User";
import { Util } from "../resources/Util";
import { checkPermUrl, appsDataUrl, getAuthHeader } from "../resources/Config";
import { DATA_LIST_OBTAINED } from "../resources/AppEvents";
import AppManager from "../providers/AppManager";
import { PortalName, Button, TopSpacing } from "../ui/Common";

type Props = {
  appManager: AppManager;
};
type State = {
  roleObtained: boolean;
  message: string;
};

export default class LoginView extends ViewWrapper<Props, State> {
  state = {
    roleObtained: false,
    message: Auth.getMsg(),
  };

  fetchData = () => {
    axios.get(appsDataUrl, { headers: getAuthHeader(Auth.token) }).then(
      (res) => {
        if (res.data) {
          const attrs = Util.fetchUniqueData(res.data.apps);
          const sasToken = res.data.sasToken;
          const apps = res.data.apps;

          this.props.appManager.triggerEvent(DATA_LIST_OBTAINED, {
            apps,
            sasToken,
            ...attrs,
          });
        }
      },
      (error) => {
        this.setState({
          message: "Something went wrong while communicating with backend server",
        });
      },
    );
  };

  defferAction(
    requestStartTS: number,
    atleast: number,
    action: () => void = () => {},
  ): Promise<any> {
    return new Promise((resolve) => {
      const diff = Date.now() - requestStartTS;
      if (diff > atleast) {
        resolve(action());
      } else {
        setTimeout(() => resolve(action()), atleast - diff);
      }
    });
  }

  componentDidMount() {
    const requestStartTS = Date.now();

    if (Auth.hasIdentity()) {
      this.setState({ message: "Checking permissions" }, () => {
        axios
          .get(checkPermUrl, { headers: getAuthHeader(Auth.token) })
          .then((res) => {
            if (res.data.role) {
              User.role = res.data.role;
            }

            if (User.role !== "guest") {
              return Promise.resolve();
            } else {
              return Promise.reject({
                roleObtained: true,
                message: (
                  <div>
                    Sorry but your permissions are insufficient
                    <br />
                    <span
                      onClick={Auth.logout}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#da4b4b",
                      }}
                    >
                      [ logout ]
                    </span>
                  </div>
                ),
              });
            }
          })
          .then(() => {
            return this.defferAction(requestStartTS, 1000, () => {
              this.setState({ message: "Downloading data" });
            });
          })
          .then(this.fetchData)
          .catch((error) => {
            console.log(error);

            if (error.roleObtained && error.message) {
              this.setState({
                roleObtained: error.roleObtained,
                message: error.message,
              });
            } else {
              this.setState({
                message: "Error occured. Check internet connection and reload the page.",
              });
            }
          });
      });
    }
  }

  handleLoginBtnClick = () => {
    return Auth.goToLogin();
  };

  content() {
    const loadingData =
      Auth.hasIdentity() &&
      !this.state.roleObtained &&
      !this.props.appNotFound &&
      !this.props.pageNotFound;

    const invalidUrl = this.props.pageNotFound;

    return (
      <div className="login-view">
        <div className="header-ctn">
          <header>
            <VolvoGroup.LightHeader />
          </header>
        </div>
        <div className={loadingData || invalidUrl ? "loading" : "content-ctn"}>
          {loadingData && <Generic.Loader />}

          <div className="message">
            {invalidUrl ? (
              <div className="text-center">
                Sorry but the specified address is incorrect
                <br />
                <a href="/" title="Home page">
                  Go to home page
                </a>
              </div>
            ) : (
              <b>{this.state.message}</b>
            )}
          </div>

          {!Auth.hasIdentity() && (
            <div className="login-ctn">
              <div className="login-ctn-loginSection">
                <PortalName />
                <TopSpacing>
                  <Button text="Login" onClick={this.handleLoginBtnClick} />
                </TopSpacing>
              </div>
            </div>
          )}
        </div>
        <VolvoGroup.StandardFooter
          style={{ marginTop: 0 }}
          menu={[
            {
              href: "https://www.volvogroup.com",
              title: "www.volvogroup.com",
            },
          ]}
        />
      </div>
    );
  }
}
