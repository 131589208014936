import React from "react";
import { Generic } from "fp-ui-components-react";

import "./styles.css";

type Props = {
  title: string;
  description: string;
  email: string;
};

const FA = Generic.FontAwesome;

// $FlowFixMe
export default React.memo(function ServiceCard({ title, description, email }: Props) {
  return (
    <div className="appportal-card">
      <div className="appportal-card-person-info">
        <p className="appportal-card-title">{title}</p>
        <p className="appportal-card-jobtitle" style={{ paddingTop: "1rem" }}>
          {description}
        </p>
        <a href={`mailto:${email}`}>
          <FA name="envelope" />
          {email}
        </a>
      </div>
    </div>
  );
});
