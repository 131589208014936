import React, { SyntheticEvent } from "react";

import styles from "./Input.module.css";

type Props = {
  value: string;
  placeholder?: string;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  onKeyDown?: () => void;
};

export function Input({ value, placeholder = "", onChange }: Props) {
  return (
    <input
      className={styles.input}
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

export function SearchInput({ value, placeholder = "", onChange, onKeyDown }: Props) {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && onKeyDown) {
      onKeyDown();
    }
  };
  return (
    <input
      className={`${styles.input} ${styles.search}`}
      type="text"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  );
}
