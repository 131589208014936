import React, { Component } from "react";
import "../../css/AppsFilter.css";
import { InitialState, FiltersSelection } from "../providers/Types";
import { App } from "../resources/Types";
import FilterField from "./Portfolio/FilterField";
import { ActionText } from "./Common";
import { User } from "../resources/User";

type Props = {
  appState: InitialState;
  getApps: () => App[];
  handleFilterChange: (property: string, value: string) => void;
  dispatchFilterChange: (filters: FiltersSelection) => void;
  style?: {};
};
type State = {
  filterSelection: FiltersSelection;
  advancedSearch: boolean;
};

export default class AppsFilter extends Component<Props, State> {
  getFilterSelectionValue(propName: string): any[] {
    if (this.props.appState.filterSelection && this.props.appState.filterSelection[propName]) {
      return this.props.appState.filterSelection[propName];
    }

    return [];
  }

  render() {
    const selectedBrands = this.getFilterSelectionValue("brand");
    const selectedBusinessProcesses = this.getFilterSelectionValue("businessProcess");
    const selectedRegions = this.getFilterSelectionValue("region");
    const selectedPlatforms = this.getFilterSelectionValue("platform");
    const selectedDevices = this.getFilterSelectionValue("device");
    const selectedTargetGroups = this.getFilterSelectionValue("targetGroup");
    const selectedBspms = this.getFilterSelectionValue("BSPM");
    const selectedTeamLeaders = this.getFilterSelectionValue("teamLeader");
    const selectedSolutionLeaders = this.getFilterSelectionValue("solutionLeader");
    const selectedBusinessKeyUsers = this.getFilterSelectionValue("businessKeyUser");
    const selectedDomainManagers = this.getFilterSelectionValue("domainManager");
    const selectedApmIDs = this.getFilterSelectionValue("apmID");
    const selectedAppStatuses = this.getFilterSelectionValue("appStatus");
    const selectedAppCategory = this.getFilterSelectionValue("appCategory");
    const selectedMaintainers = this.getFilterSelectionValue("managedBy");
    const {
      brand,
      businessProcess,
      region,
      platform,
      device,
      targetGroup,
      BSPM,
      teamLeader,
      solutionLeader,
      businessKeyUser,
      apmID,
      appStatus,
      appCategory,
      domainManager,
      managedBy,
    } = this.props.appState;
    const { handleFilterChange, getApps } = this.props;
    const apps = getApps();
    const appsCount = apps ? apps.length : 0;

    return (
      /* $FlowFixMe */
      <div className="apps-filter container-fluid" style={this.props.style}>
        <div className="row">
          <div className="col-12">
            <h3 className="filter-label">Filter by</h3>
            <ActionText text={`${appsCount} ${appsCount === 1 ? "app" : "apps"} found`} />
          </div>
          <div className="col-12">
            <FilterField
              title="brand"
              availableOptions={brand}
              selectedOptions={selectedBrands}
              propertyName="brand"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="business process"
              availableOptions={businessProcess}
              selectedOptions={selectedBusinessProcesses}
              propertyName="businessProcess"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="region"
              availableOptions={region}
              selectedOptions={selectedRegions}
              propertyName="region"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="platform"
              availableOptions={platform}
              selectedOptions={selectedPlatforms}
              propertyName="platform"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="device"
              availableOptions={device}
              selectedOptions={selectedDevices}
              propertyName="device"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="targetGroup"
              availableOptions={targetGroup}
              selectedOptions={selectedTargetGroups}
              propertyName="targetGroup"
              onChange={handleFilterChange}
            />
          </div>

          <div className="col-12">
            <FilterField
              title="BSPM"
              availableOptions={BSPM}
              selectedOptions={selectedBspms}
              propertyName="BSPM"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="delivery leaders"
              availableOptions={teamLeader}
              selectedOptions={selectedTeamLeaders}
              propertyName="teamLeader"
              onChange={handleFilterChange}
            />
          </div>
          <div className="col-12">
            <FilterField
              title="solution Leaders"
              availableOptions={solutionLeader}
              selectedOptions={selectedSolutionLeaders}
              propertyName="solutionLeader"
              onChange={handleFilterChange}
            />
          </div>

          <div className="col-12">
            <FilterField
              title="Key Users"
              availableOptions={businessKeyUser}
              selectedOptions={selectedBusinessKeyUsers}
              propertyName="businessKeyUser"
              onChange={handleFilterChange}
            />
          </div>
          {User.isAdmin() ? (
            <div className="col-12">
              <FilterField
                title="Domain Managers"
                availableOptions={domainManager}
                selectedOptions={selectedDomainManagers}
                propertyName="domainManager"
                onChange={handleFilterChange}
              />
            </div>
          ) : null}

          <div className="col-12">
            <FilterField
              title="APM IDs"
              availableOptions={apmID}
              selectedOptions={selectedApmIDs}
              propertyName="apmID"
              onChange={handleFilterChange}
            />
          </div>

          <div className="col-12">
            <FilterField
              title="app Status"
              availableOptions={appStatus}
              selectedOptions={selectedAppStatuses}
              propertyName="appStatus"
              onChange={handleFilterChange}
            />
          </div>

          <div className="col-12">
            <FilterField
              title="app Category"
              availableOptions={appCategory}
              selectedOptions={selectedAppCategory}
              propertyName="appCategory"
              onChange={handleFilterChange}
            />
          </div>

          <div className="col-12">
            <FilterField
              title="Managed By"
              availableOptions={managedBy}
              selectedOptions={selectedMaintainers}
              propertyName="managedBy"
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
