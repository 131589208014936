import React from "react";
import ReactDOM from "react-dom";
import { APP_URL_CHANGED } from "./app/resources/AppEvents";
import * as AppEvents from "./app/resources/AppEvents";
import Viewport from "./app/scenes/Viewport";
import { initAuth } from "./app/services/AuthHelper";
import AppManager, { Consumer as AppConsumer } from "./app/providers/AppManager";
import { initAppInsights } from "./app/services/AppInsights";
import "airr-react/dist/airr-react.css";
import "bootstrap/dist/css/bootstrap.css";
import "fp-ui-components-react/dist/VolvoGroup/styles.css";
import "./css/main.css";

const appInsights = initAppInsights();

const root: HTMLElement | null = document.getElementById("root");

const urlTrackingCallback = function urlTrackingCallback() {
  if (appInsights) {
    appInsights.trackPageView();
  }
};

if (root) {
  ReactDOM.render(
    <AppManager
      events={Object.values(AppEvents)}
      appUrlChangeEventName={APP_URL_CHANGED}
      urlTrackingCallback={urlTrackingCallback}
    >
      <AppConsumer>
        {({ appState, appManager, history }) => {
          appManager && initAuth(appManager);
          return (
            <Viewport
              {...{
                appState,
                history,
                appManager,
              }}
            />
          );
        }}
      </AppConsumer>
    </AppManager>,
    root,
  );
} else {
  console.error("[AppPortal] No root element found.");
}
