import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const initAppInsights = function initAppInsights() {
  let appInsights;
  const appInsightsKey = process.env.REACT_APP_APPINSIGHTS_KEY;
  if (appInsightsKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
      },
    });
    appInsights.loadAppInsights();
    if (!window.location.href.includes("access_token")) {
      appInsights.trackPageView();
    }
  }
  return appInsights;
};
