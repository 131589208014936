import React from "react";

import { Generic } from "fp-ui-components-react";
import { Util } from "../../../resources/Util";

import styles from "../Utils.module.css";

type Props = {
  platforms: string[];
  useBiggerIcons?: boolean;
};

const FA = Generic.FontAwesome;

export default function Platforms({ platforms, useBiggerIcons = false }: Props) {
  const className = `${styles.platforms} ${useBiggerIcons === true ? styles.biggerIcons : ""}`;
  let platformsRender = null;

  if (Array.isArray(platforms)) {
    platforms.sort();
    platformsRender = platforms.map((platform) => (
      <FA
        key={platform}
        name={Util.getFAPlatformName(platform)}
        type={Util.getIconType(platform)}
      />
    ));
  }

  return (
    <div className={className}>
      <div className={styles.title}>OS</div>
      <div>{platformsRender}</div>
    </div>
  );
}
