export const APP_URL_CHANGED = "APP_URL_CHANGED";
export const DATA_LIST_OBTAINED = "DATA_LIST_OBTAINED";
export const DATA_LIST_CLEARED = "DATA_LIST_CLEARED";
export const DATA_LIST_FILTERED = "DATA_LIST_FILTERED";
export const DATA_TEXT_SEARCH_CHANGE = "DATA_TEXT_SEARCH_CHANGE";
export const DATA_ADD_APP = "DATA_ADD_APP";
export const DATA_DELETE_APP = "DATA_DELETE_APP";
export const DATA_APP_CHANGED = "DATA_APP_CHANGED";
export const DATA_APP_UPDATE = "DATA_APP_UPDATE";
export const DATA_APP_UPDATING = "DATA_APP_UPDATING";
export const DATA_APP_UPDATED = "DATA_APP_UPDATED";
export const PAGINATION_INCREMENT_PAGE = "PAGINATION_INCREMENT_PAGE";
export const PAGINATION_RESET_PAGE = "PAGINATION_RESET_PAGE";
export const TOGGLE_SHOW_FILTERS = "TOGGLE_SHOW_FILTERS";
export const RENDER_MODE_CHANGED = "RENDER_MODE_CHANGED";
export const APP_DATA_REVERT_CHANGES = "APP_DATA_REVERT_CHANGES";
