import React, { PureComponent } from "react";
import styles from "./ImageThumbnail.module.css";

type Props = {
  url: string;
  isPromo?: Boolean;
};
type State = {
  loaded: boolean;
  backgroundStyle?: { backgroundImage?: string };
};
class ImageThumbnail extends PureComponent<Props, State> {
  state = {
    loaded: false,
    backgroundStyle: null,
  };

  image: Image;

  loadImage = () => {
    if (this.props.url) {
      this.image = new Image();

      this.image.onload = () => {
        if (!this.unmounted) {
          this.setState({
            loaded: true,
            backgroundStyle: { backgroundImage: `url("${this.props.url}")` },
          });
        }
      };
      this.image.src = this.props.url;
    }
  };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate() {
    this.loadImage();
  }

  unmounted: boolean = false;
  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const className =
      this.props.isPromo && this.props.isPromo === true ? styles.promoImage : styles.image;
    return (
      <div className={className}>
        <div
          className={styles.imageFallback}
          data-loaded={this.state.loaded ? "true" : "false"}
          style={this.state.backgroundStyle}
        />
      </div>
    );
  }
}

export default ImageThumbnail;
