import cleanInputValue from "../../../utils/Sanitization";

const CellFormatter = ({ value }: any) => {
  try {
    const cleanedValue = cleanInputValue(value);
    if (Array.isArray(cleanedValue) && cleanedValue.length > 0) {
      return cleanedValue.join(", ");
    } else {
      return cleanedValue;
    }
  } catch (err) {
    console.log(err);
  }
  return "";
};

export default CellFormatter;
