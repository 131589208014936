import React from "react";
import styles from "./TextBox.module.css";

type Props = {
  children?: any;
};

export function TextBox({ children }: Props) {
  return <p className={styles.textbox}>{children}</p>;
}

export function AlternateTextBox({ children }: Props) {
  return <p className={`${styles.textbox} ${styles.alternate}`}>{children}</p>;
}

export function BorderBlueTextBox({ children }: Props) {
  return <p className={`${styles.textbox} ${styles.blueBorder}`}>{children}</p>;
}
