import React, { createContext } from "react";
import pathToRegexp, { Path } from "path-to-regexp";
import { Update, createBrowserHistory } from "history";
import {
  Props,
  InitialState,
  LinkProps,
  ProviderData,
  RouteProps,
  FiltersSelectionKeys,
} from "./Types";
import { Util } from "../resources/Util";
import { App } from "../resources/Types";

const history = createBrowserHistory({});
const filterSelectionSearch = Util.querySearchToObject(window.location.search);
const filterSelectionSearchActiveOnly = filterSelectionSearch.appStatus
  ? filterSelectionSearch
  : { ...filterSelectionSearch, appStatus: ["Production"] };

export const initialState: InitialState = {
  url: window.location.pathname,
  advancedSearch: false, //currently unsed, deprecated
  appChanged: false,
  apps: [],
  sasToken: undefined,
  updatedAppIndex: undefined,
  size: 20,
  page: 1,
  //
  renderMode: window.innerWidth < 992 ? "mobile" : "desktop",
  //applied text search and filters
  textSearch: "",
  filterSelection: filterSelectionSearchActiveOnly,
  showFilters: Object.keys(filterSelectionSearch).length ? true : false,
  //possible filter values
  brand: undefined,
  businessProcess: undefined,
  region: undefined,
  platform: undefined,
  device: undefined,
  teamLeader: undefined,
  solutionLeader: undefined,
  targetGroup: undefined,
  BSPM: undefined,
  businessKeyUser: undefined,
  apmID: undefined,
  domainManager: undefined,
  appStatus: undefined,
  managedBy: undefined,
  appCategory: undefined,
};

type EventListener = (stateChange: InitialState, currentState: InitialState) => void;
let events: string[] = [];
const listeners: { [key: string]: EventListener[] } = {};
const defaultValue: ProviderData = { history, appState: initialState, appManager: undefined };
const Context = createContext(defaultValue);
const { Provider, Consumer } = Context;

class AppManager extends React.Component<Props, InitialState> {
  state = initialState;

  constructor(props: Props) {
    super(props);

    this.registerEvents(props.events);

    if (props.appUrlChangeEventName && props.events.indexOf(props.appUrlChangeEventName) === -1) {
      console.warn(
        "[AppManager] props.appUrlChangeEventName is missing in possible props.events list",
      );
    }

    // Listen for changes to the current location.
    history.listen((update: Update) => {
      const { location, action } = update;
      // location is an object like window.location
      if (action === "POP" && this.state.url !== location.pathname) {
        //POP means browser navigation buttons were used (back,forward)
        this.performUrlChange(location.pathname);
      }
    });
  }

  get listeners(): { [key: string]: EventListener[] } {
    return listeners;
  }

  goTo = (url: string, force: boolean = false): Promise<void> => {
    return new Promise((resolve) => {
      if ((!force && this.state && this.state.url !== url) || force) {
        history.push(url);
        this.performUrlChange(url).then(resolve);
      } else {
        resolve();
      }
    });
  };

  performUrlChange(url: string): Promise<void> {
    return new Promise((resolve) => {
      const { appUrlChangeEventName } = this.props;
      if (appUrlChangeEventName) {
        this.setState({ appChanged: false }, () => {
          this.triggerEvent(appUrlChangeEventName, { url }).then(resolve);
        });
      } else {
        this.setState({ url }, resolve);
      }

      this.trackPageView();
    });
  }

  replaceUrl = (url: string) => {
    history.replace(url);
    this.setState({ url });
    this.trackPageView();
  };

  trackPageView() {
    if (typeof this.props.urlTrackingCallback === "function") {
      this.props.urlTrackingCallback();
    }
  }

  registerEvents = (eventsNamesArray: string[]) => {
    events = eventsNamesArray.reduce((prev, curr) => {
      prev.push(curr);
      return prev;
    }, [] as string[]);
  };

  _checkEventRegistered = (eventName: string) => {
    if (events.indexOf(eventName) === -1) {
      throw new Error(`Event name: '${eventName}' is not registered`);
    }

    return true;
  };

  addEventListener = (eventName: string, listener: EventListener) => {
    this._checkEventRegistered(eventName);

    if (typeof listener !== "function") {
      throw new Error("Passed listener is not a function");
    }

    if (!Array.isArray(this.listeners[eventName])) {
      this.listeners[eventName] = [];
    }

    this.listeners[eventName].push(listener);
  };

  removeEventListener = (eventName: string, listener: EventListener) => {
    if (Array.isArray(this.listeners[eventName])) {
      const listnerIndex = this.listeners[eventName].findIndex(
        (item: EventListener) => item === listener,
      );
      if (listnerIndex !== -1) {
        this.listeners[eventName].splice(listnerIndex, 1);
        return true;
      }
    }

    return false;
  };

  filterByRetiredApps = (newState: InitialState) => {
    const retiredMembers: FiltersSelectionKeys[] = [
      "teamLeader",
      "BSPM",
      "solutionLeader",
      "businessKeyUser",
      "domainManager",
    ];
    const { apps } = newState;

    const filteredState = newState;
    for (const member of retiredMembers) {
      if (newState[member]) {
        for (const leader of newState[member] as string[]) {
          if (
            apps !== undefined &&
            !apps.some(
              (app: App) =>
                (app[member] as string[]).find((t) => t === leader) && app.appStatus !== "Retired",
            )
          ) {
            // @ts-ignore
            filteredState[member] = (filteredState[member] as string[]).filter(
              (tl) => tl !== leader,
            );
          }
        }
      }
    }

    return filteredState;
  };

  triggerEvent = (eventName: string, stateChange: InitialState): Promise<void> => {
    return new Promise((resolve) => {
      this._checkEventRegistered(eventName);

      let filteredState = stateChange;

      if (eventName === "DATA_LIST_OBTAINED") {
        filteredState = this.filterByRetiredApps(stateChange);
      }

      this.setState(filteredState, () => {
        if (Array.isArray(this.listeners[eventName])) {
          this.listeners[eventName].forEach((listener) => listener(filteredState, this.state));
        }

        resolve();
      });
    });
  };

  render() {
    const providerDataValue: ProviderData = {
      history,
      appManager: this,
      appState: this.state,
    };
    return <Provider value={providerDataValue}>{this.props.children}</Provider>;
  }
}

let lastLinkClickTimeStamp = 0;

function Link({ pathTo, children, className, style }: LinkProps) {
  return (
    <Consumer>
      {({ appManager }: ProviderData) => (
        // eslint-disable-next-line
        <a
          className={className}
          style={style}
          onClick={(e) => {
            e.preventDefault();
            //prevent from multi chaos clicks :) wait for animation fo finish
            if (appManager && lastLinkClickTimeStamp + 500 < Date.now()) {
              lastLinkClickTimeStamp = Date.now();
              appManager.goTo(pathTo);
            }
          }}
        >
          {children}
        </a>
      )}
    </Consumer>
  );
}

function Route(props: RouteProps) {
  return (
    <Consumer>
      {({ appState }: ProviderData) => {
        let re;

        if (props.test) {
          re = props.test;
        } else {
          re = pathToRegexp(props.path as Path);
        }

        if (appState && appState.url && re.test(appState.url)) {
          return props.children;
        }
        return <></>;
      }}
    </Consumer>
  );
}

export { AppManager as default, Consumer, Link, Route };
