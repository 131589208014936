// $FlowFixMe
import React, { useState, useEffect, SyntheticEvent } from "react";

import "./styles.css";
import { SearchInput } from "../Input/Input";
import { ActionButton } from "../Button/Button";

type Props = {
  initialText?: string;
  placeholder: string;
  onClick: (searchText: string) => void;
};

export function SearchForm({ placeholder = "", initialText = "", onClick }: Props) {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  const setTextCallback = (event: SyntheticEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };

  const clickSearchCallback = () => {
    onClick(text);
  };

  return (
    <div className="appportal-inline-search">
      <SearchInput
        value={text}
        placeholder={placeholder}
        onChange={setTextCallback}
        onKeyDown={clickSearchCallback}
      />
      <ActionButton
        text="Search"
        onClick={clickSearchCallback}
        disabled={text === "" || text === undefined}
      />
    </div>
  );
}
