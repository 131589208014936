import { Generic } from "fp-ui-components-react";
import { ReactNode } from "react";
import React from "react";
import cleanInputValue from "../utils/Sanitization";

const MultiSelect = Generic.MultiSelect;
const AutoCompleteInput = Generic.AutoCompleteInput;
const MultiAutoCompleteInput = Generic.MultiAutoCompleteInput;

type Props = {
  inEditState: boolean;
  name: string;
  fieldType: "autocomplete" | "multiautocomplete" | "multiselect";
  selected: string[] | string;
  items: string[];
  props?: {};
  onChange: (val: string[] | string) => void;
  render?: (selected: string[] | string) => ReactNode;
  children?: (selected: string[] | string) => ReactNode;
};

const sanitizeValues = (currentValue: string, newValue: string): string => {
  let cleanedCode = newValue;
  if (currentValue && currentValue.length < newValue.length) {
    cleanedCode = cleanInputValue(newValue);
  }

  return cleanedCode;
};

const consoleChange = (newValue: string | string[], currentValue?: string): string | string[] => {
  try {
    if (Array.isArray(newValue)) {
      return newValue.map((val) => cleanInputValue(val));
    } else {
      return sanitizeValues(currentValue, newValue);
    }
  } catch (err) {
    console.log(err);
  }
  return "";
};

export default function AppFilterField({
  inEditState,
  name,
  fieldType = "multiselect",
  selected,
  items,
  onChange,
  props = {},
  render,
  children,
}: Props) {
  if (inEditState) {
    let FieldType;
    switch (fieldType) {
      case "multiselect":
        FieldType = MultiSelect;
        break;
      case "autocomplete":
        FieldType = AutoCompleteInput;
        break;
      case "multiautocomplete":
        FieldType = MultiAutoCompleteInput;
        break;
      default:
        FieldType = MultiSelect;
        break;
    }

    return (
      <FieldType
        selected={selected}
        items={items}
        title={name}
        hideClearIcon={true}
        handleItemsChange={(e) => onChange(consoleChange(e, selected))}
        {...props}
      />
    );
  } else {
    return typeof render === "function"
      ? render(selected)
      : typeof children === "function"
        ? children(selected)
        : children;
  }
}
