import React from "react";
import { Generic } from "fp-ui-components-react";
import styles from "./Button.module.css";

type Props = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

const FA = Generic.FontAwesome;

export function Button({ text, onClick }: Props) {
  return (
    <button className={styles.button} onClick={onClick}>
      {text}
      <FA name="chevron-right" />
    </button>
  );
}

export function SmallButton({ text, onClick }: Props) {
  return (
    <button className={`${styles.button} ${styles.smaller}`} onClick={onClick}>
      {text}
      <FA name="chevron-right" />
    </button>
  );
}

export function AlternateButton({ text, onClick }: Props) {
  return (
    <button className={`${styles.button} ${styles.alternate}`} onClick={onClick}>
      {text}
      <FA name="chevron-right" />
    </button>
  );
}

export function ActionButton({ text, onClick, disabled = false }: Props) {
  return (
    <button className={`${styles.button} ${styles.action}`} onClick={onClick} disabled={disabled}>
      {text}
      <FA name="chevron-right" />
    </button>
  );
}

export function SmallActionButton({ text, onClick, disabled = false }: Props) {
  return (
    <button
      className={`${styles.button} ${styles.action} ${styles.smaller}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}&nbsp;
      <FA name="chevron-right" />
    </button>
  );
}

export function SmallActionSecondaryButton({ text, onClick, disabled = false }: Props) {
  return (
    <button
      className={`${styles.button} ${styles.action} ${styles.smaller} ${styles.secondary}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}&nbsp;
      <FA name="chevron-right" />
    </button>
  );
}

export function FilterButton({ text, onClick }: Props) {
  return (
    <button className={`${styles.button} ${styles.filter}`} onClick={onClick}>
      <span>{text}&nbsp;</span>
      <FA name="times" />
    </button>
  );
}
