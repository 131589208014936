import { upperFirst, camelCase } from "lodash";
import { Android, Native, iOS } from "../resources/Config";
import { containsMobileTech } from "./Technology";
import { DevOpsRepo } from "../resources/Types";

const getPascalCase = (value: string): string => upperFirst(camelCase(value));

const getRepoName = (name: string, tech: string): string =>
  getPascalCase(name) + (tech === iOS ? iOS : getPascalCase(tech));

export const getDevOpsRepos = (
  name: string,
  platform: string[],
  technology: string,
  includesBackend: boolean,
): DevOpsRepo[] => {
  const repos = [];
  if (platform.includes(iOS) && technology === Native) {
    repos.push({
      type: iOS,
      name: getRepoName(name, iOS),
    });
  }

  if (platform.includes(Android) && technology === Native) {
    repos.push({
      type: Android,
      name: getRepoName(name, Android),
    });
  }

  if (containsMobileTech(platform) && technology !== Native) {
    repos.push({
      type: technology,
      name: getRepoName(name, technology),
    });
  }

  if (platform.includes("Web")) {
    repos.push({ type: "Web", name: getRepoName(name, "Web") });
  }

  if (includesBackend === true) {
    repos.push({ type: "Backend", name: getRepoName(name, "Backend") });
  }

  return repos;
};
