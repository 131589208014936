import React, { Component } from "react";
import ReactDOM from "react-dom";
import CreatableSelect from "react-select";
import cleanInputValue from "../../../utils/Sanitization";

type Props = {
  value?: string[];
  column?: { [key: string]: any };
  options: string[];
  onCommit?: () => void;
};

type State = {
  field: string[];
};

export default class MultiSelectEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { field: props.value || [] };
  }

  getValue() {
    return { [this.props.column?.key]: this.state.field };
  }

  getInputNode() {
    return (ReactDOM.findDOMNode(this) as HTMLInputElement).getElementsByTagName("input")[0];
  }

  handleChangeComplete = (selectedOption: any) => {
    this.setState(
      {
        field: selectedOption.map((option: any): string => {
          const cleanedOption = cleanInputValue(option.value);
          try {
            if (cleanedOption.length > 0) {
              return cleanedOption;
            } else {
              return "";
            }
          } catch (err) {
            console.log(err);
            return "";
          }
        }),
      },
      () => {
        if (this.props.onCommit) {
          this.props.onCommit();
        }
      },
    );
  };

  render() {
    const { options } = this.props;
    const { field } = this.state;
    const mapper = (option: string) => {
      return {
        value: option,
        label: option,
      };
    };
    const selectOptions = options.map(mapper);
    const defaults = field.map(mapper);

    return (
      <CreatableSelect
        defaultValue={defaults}
        isMulti={true}
        options={selectOptions}
        onChange={this.handleChangeComplete}
      />
    );
  }
}
