import React from "react";
import styles from "./Text.module.css";

type Props = {
  text: string;
};

export function ActionText({ text }: Props) {
  return <span className={styles.action}>{text}</span>;
}
