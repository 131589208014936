import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./index.css";
import cleanInputValue from "../../../utils/Sanitization";

type Props = {
  value: string;
  column: { [key: string]: any };
  options: string[];
  onCommit: () => void;
};

type State = {
  field: string;
};

export default class NumericEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { field: props.value };
  }

  getValue() {
    return { [this.props.column.key]: this.state.field };
  }

  getInputNode() {
    /* eslint-disable react/no-find-dom-node */
    return (ReactDOM.findDOMNode(this) as HTMLInputElement).getElementsByTagName("input")[0];
    /* eslint-enable */
  }

  handleChange = (event: any) => {
    const field = (event.target as HTMLInputElement).value;
    const cleanedField = cleanInputValue(field);
    try {
      if (cleanedField.length > 0) {
        this.setState({ field });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <input
        className="numeric-editor"
        type="number"
        onChange={this.handleChange}
        value={this.state.field}
      />
    );
  }
}
