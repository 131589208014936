import React, { Component } from "react";
import ReactDataGrid from "react-data-grid";
import { Generic } from "fp-ui-components-react";
import { AppStatuses, Devices, Platforms } from "../../resources/Config";

import { columns as allColumns, baseColumns } from "./columns";
import * as Formatters from "./Formatters";
import { DropDownEditor, MultiSelectEditor, NumericEditor } from "./Editors";

import ExcelExportButton from "./ExcelExportButton";

const FA = Generic.FontAwesome;

const numericFields = [
  "budget2018",
  "budget2019",
  "budget2020",
  "actual2018",
  "actual2019",
  "actual2020",
];

type Props = {
  data: any[];
  apmIdMode: boolean;
  toggleMode: () => void;
  saveChanges: (changes: any[]) => void;
};

type State = {
  rows: any[];
};

export default class Grid extends Component<Props, State> {
  state = {
    rows: [],
  };

  componentDidUpdate(prevProps: Props) {
    const currentData = prevProps.data;
    const newData = this.props.data;
    if (newData.length !== currentData.length) {
      this.setState({
        rows: this.props.data,
      });
    }
  }

  _dirtyOnes = (row: any) => row.isDirty === true;
  _needsToBeSaved = () => {
    const { rows } = this.state;
    const modifiedRows = rows.filter(this._dirtyOnes);
    return modifiedRows.length > 0;
  };

  _onGridRowsUpdated = ({
    fromRow,
    toRow,
    updated,
  }: {
    fromRow: any;
    toRow: any;
    updated: any;
  }) => {
    const { rows } = this.state;
    for (let i = fromRow; i <= toRow; ++i) {
      rows[i] = { ...rows[i], ...updated, isDirty: true };
    }

    this.setState({ rows });
  };

  _saveChanges = () => {
    this.props.saveChanges(this.state.rows.filter(this._dirtyOnes));
    const { rows } = this.state;
    const rowsLength = rows.length;
    for (let i = 0; i < rowsLength; ++i) {
      rows[i] = { ...rows[i], isDirty: false };
    }

    this.setState({ rows });
  };

  //#region RENDERING
  getOptions = (field: any): string[] => {
    const { rows } = this.state;
    const allValues = rows.reduce((accumulator, app) => {
      if (Array.isArray(app[field])) {
        accumulator = accumulator.concat(...app[field]);
      } else if (app[field] !== undefined && app[field] !== "") {
        accumulator.push(app[field]);
      }

      return accumulator;
    }, []);

    return [...allValues];
  };

  getColumnSet = () => {
    return this.props.apmIdMode === true ? baseColumns : allColumns;
  };

  getColumns = () => {
    const StatusEditor = <DropDownEditor options={AppStatuses} />;
    const BrandEditor = <DropDownEditor options={this.getOptions("brand")} />;
    const DeviceEditor = <MultiSelectEditor options={Devices} />;
    const PlatformEditor = <MultiSelectEditor options={Platforms} />;
    const TargetGroupEditor = <MultiSelectEditor options={this.getOptions("targetGroup")} />;

    const RegionEditor = <MultiSelectEditor options={this.getOptions("region")} />;
    const SolutionLeaderEditor = <MultiSelectEditor options={this.getOptions("solutionLeader")} />;
    const BusinessAnalystEditor = (
      <MultiSelectEditor options={this.getOptions("businessAnalyst")} />
    );
    const BSPMEditor = <MultiSelectEditor options={this.getOptions("BSPM")} />;
    const DeliveryManagerEditor = (
      <MultiSelectEditor options={this.getOptions("deliveryManager")} />
    );
    const DomainManagerEditor = <MultiSelectEditor options={this.getOptions("domainManager")} />;
    const TeamLeaderEditor = <MultiSelectEditor options={this.getOptions("teamLeader")} />;
    const BusinessKeyUserEditor = (
      <MultiSelectEditor options={this.getOptions("businessKeyUser")} />
    );
    const BusinessProcessEditor = (
      <MultiSelectEditor options={this.getOptions("businessProcess")} />
    );

    const editorsMap = new Map();
    editorsMap.set("appStatus", StatusEditor);
    editorsMap.set("brand", BrandEditor);
    editorsMap.set("platform", PlatformEditor);
    editorsMap.set("device", DeviceEditor);
    editorsMap.set("targetGroup", TargetGroupEditor);
    editorsMap.set("region", RegionEditor);
    editorsMap.set("solutionLeader", SolutionLeaderEditor);
    editorsMap.set("businessAnalyst", BusinessAnalystEditor);
    editorsMap.set("BSPM", BSPMEditor);
    editorsMap.set("deliveryManager", DeliveryManagerEditor);
    editorsMap.set("domainManager", DomainManagerEditor);
    editorsMap.set("teamLeader", TeamLeaderEditor);
    editorsMap.set("businessKeyUser", BusinessKeyUserEditor);
    editorsMap.set("businessProcess", BusinessProcessEditor);
    editorsMap.set("budget2018", NumericEditor);
    editorsMap.set("budget2019", NumericEditor);
    editorsMap.set("budget2020", NumericEditor);
    editorsMap.set("actual2018", NumericEditor);
    editorsMap.set("actual2019", NumericEditor);
    editorsMap.set("actual2020", NumericEditor);

    const defaultColumnProperties = {
      resizable: true,
      editable: true,
      formatter: Formatters.CellFormatter,
      width: 200,
    };

    const columnsSet = this.getColumnSet();

    return columnsSet.map((col: any) =>
      Object.assign({}, defaultColumnProperties, {
        key: col.field,
        name: col.title,
        frozen: col.frozen,
        editable: col.editable === false ? col.editable : defaultColumnProperties.editable,
        formatter:
          col.field === "names"
            ? Formatters.NamesFormatter
            : numericFields.includes(col.field)
              ? Formatters.BudgetFormatter
              : Formatters.CellFormatter,
        width: col.width ? col.width : defaultColumnProperties.width,
        editor: editorsMap.get(col.field),
      }),
    );
  };
  //#endregion

  render() {
    const { rows } = this.state;
    const { apmIdMode } = this.props;

    const iconName = apmIdMode === true ? "toggle-on" : "toggle-off";
    const fileName = apmIdMode === true ? "Budget" : "VolvoApps";
    const rowHeight = apmIdMode === true ? 4 * 35 : 35;
    const columnSet = this.getColumnSet();
    const rowGetter = (i: number) => rows[i];
    const rowsCount = rows.length;

    if (rows.length > 0) {
      const columns = this.getColumns();
      return (
        <React.Fragment>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <ExcelExportButton data={this.props.data} columns={columnSet} fileName={fileName} />
              {this._needsToBeSaved() && (
                <button
                  className="btn btn-sm btn-dark button--fixedWidth ml-1"
                  onClick={this._saveChanges}
                >
                  <FA name="save" />
                  &nbsp; Save Changes
                </button>
              )}
            </div>
            <div className="c-pointer d-flex justify-items-around" onClick={this.props.toggleMode}>
              <FA name={iconName} size="20px" />
              <span className="ml-2">APM ID table</span>
            </div>
          </div>
          <ReactDataGrid
            styles={{ marginRight: "0.5rem" }}
            columns={columns}
            rowGetter={rowGetter}
            rowsCount={rowsCount}
            minHeight={600}
            rowHeight={rowHeight}
            headerRowHeight={35}
            onGridRowsUpdated={this._onGridRowsUpdated}
            enableCellSelect={true}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
