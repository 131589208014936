import React, { SyntheticEvent } from "react";
// @ts-ignore
import { ViewWrapper, View, FX } from "airr-react";
import Auth from "volvo-ping-auth-helper";
import { VolvoGroup } from "fp-ui-components-react";

import MainScene from "../scenes/MainScene";
import Menu from "../ui/Menu";
import "../../css/FrontView.css";
import { User } from "../resources/User";

import { Props as MainSceneProps } from "../scenes/MainScene";

type Props = {
  refCOMPMainScene: { current?: {} };
  mainSceneProps: MainSceneProps;
  getFiltersSelectionUrlSuffix: () => string;
};
type State = {
  stickMenu: boolean;
};
export default class FrontView extends ViewWrapper<Props, State> {
  state = {
    stickMenu: false,
  };

  refDOMMenu: { current?: any } = React.createRef();
  initialMenuOffsetTop: number = null;
  scrollOnBottomHandler: () => void;

  handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    if (e.currentTarget === this.refDOM.current) {
      if (!this.initialMenuOffsetTop) {
        this.initialMenuOffsetTop =
          this.refDOMMenu && this.refDOMMenu.current && this.refDOMMenu.current.offsetTop;
      }

      if (this.initialMenuOffsetTop && e.currentTarget.scrollTop > this.initialMenuOffsetTop) {
        if (!this.state.stickMenu) {
          this.setState({ stickMenu: true });
        }
      } else {
        if (this.state.stickMenu) {
          this.setState({ stickMenu: false });
        }
      }

      if (
        e.currentTarget.offsetHeight + e.currentTarget.scrollTop === e.currentTarget.scrollHeight &&
        this.scrollOnBottomHandler
      ) {
        this.scrollOnBottomHandler();
      }
    }
  };

  setScrollOnBottomHandler = (handler: () => void) => {
    this.scrollOnBottomHandler = handler;
  };

  scrollToTop = () => {
    if (this.refDOM.current) {
      FX.doVerticalScrollAnimation(this.refDOM.current, 600, "top");
    }
  };

  render() {
    return (
      <View {...this.getViewProps()} onScroll={this.handleScroll}>
        {() => this.content()}
      </View>
    );
  }

  content() {
    const menuCtnStyle = {},
      menuHeight =
        this.refDOMMenu.current && this.refDOMMenu.current.clientHeight
          ? this.refDOMMenu.current.clientHeight + "px"
          : "";
    let menuExtraClass = "";

    if (this.state.stickMenu) {
      menuCtnStyle.paddingTop =
        this.refDOMMenu && this.refDOMMenu.current && this.refDOMMenu.current.clientHeight;
      menuExtraClass = "sticky";
    }

    return (
      <div className="front-view">
        <header>
          <VolvoGroup.LightHeader
            isAdmin={User.isAdmin()}
            username={Auth.hasIdentity() ? Auth.data.username : undefined}
          />

          <div style={menuCtnStyle}>
            <Menu
              refDOMMenu={this.refDOMMenu}
              className={menuExtraClass}
              getFiltersSelectionUrlSuffix={this.props.getFiltersSelectionUrlSuffix}
            />
          </div>
        </header>
        <MainScene
          {...this.props.mainSceneProps}
          ref={this.props.refCOMPMainScene}
          scrollToTop={this.scrollToTop}
          stickMenu={this.state.stickMenu}
          menuHeight={menuHeight}
          setScrollOnBottomHandler={this.setScrollOnBottomHandler}
        />
        <VolvoGroup.StandardFooter
          style={{ marginTop: 0 }}
          menu={[
            {
              href: "https://www.volvogroup.com",
              title: "www.volvogroup.com",
            },
          ]}
        />
        {/* <ChatBot /> */}
      </div>
    );
  }
}
