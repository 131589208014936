import React, { Component } from "react";
// @ts-ignore
import { Generic } from "fp-ui-components-react";
// @ts-ignore
import ReactExport from "fp-fork-react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FA = Generic.FontAwesome;

type Props = {
  fileName: string;
  data: any[];
  columns: any[];
};

export default class ExcelExportButton extends Component<Props> {
  // make each property a string instead of Array
  // because array is crashing react-export-excel lib
  flattenedData = () => {
    this.props.data.forEach((row) => {
      for (const key in row) {
        if (Array.isArray(row[key])) {
          row[key] = row[key].join(", ");
        }
      }
    });
    return this.props.data;
  };

  render() {
    const { fileName, columns } = this.props;
    return (
      <ExcelFile
        element={
          <button>
            <FA name="file-excel" />
            &nbsp; Export to Excel
          </button>
        }
        filename={fileName}
      >
        <ExcelSheet data={this.flattenedData} name="Sheet1">
          {columns.map((column, index) => {
            return <ExcelColumn key={index} label={column.title} value={column.field} />;
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}
