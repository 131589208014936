import React from "react";
import { SmallButton } from "./Common";

type Props = {
  renderMode: "mobile" | "desktop";
  showFilters: boolean;
  openSidepanel: () => void;
  triggerToggleShowFilters: () => void;
};
export default function FilterBtn({
  renderMode,
  showFilters,
  openSidepanel,
  triggerToggleShowFilters,
}: Props) {
  const onClick = renderMode === "mobile" ? openSidepanel : triggerToggleShowFilters;
  const label =
    renderMode === "mobile" ? "filter data" : showFilters ? "hide filters" : "show filters";
  return <SmallButton text={label} onClick={onClick} />;
}
