import React, { Component, SyntheticEvent } from "react";
import { Generic } from "fp-ui-components-react";
import { Consumer as AppConsumer } from "../../providers/AppManager";
import { User } from "../../resources/User";

const FA = Generic.FontAwesome;

export const storageKeyAdminEditOn = "appPortal-AdminEditOn";

type Props = {
  handleEditToogle: (on: boolean) => void;
  handleRevertChanges: (e: SyntheticEvent<"i">) => void;
  handleUpdateApp: (e: SyntheticEvent<"i">) => void;
};
type State = {
  on: boolean;
};
export default class NavbarAdminMenu extends Component<Props, State> {
  state = {
    on: Boolean(parseInt(localStorage.getItem(storageKeyAdminEditOn), 10)),
  };

  handleToogle = () => {
    this.setState(
      ({ on }) => {
        return { on: !on };
      },
      () => {
        localStorage.setItem(storageKeyAdminEditOn, this.state.on ? "1" : "0");
        this.props.handleEditToogle(this.state.on);
      },
    );
  };

  render() {
    return User.isAdmin() ? (
      <AppConsumer key="navbaredit">
        {({ appState: { appChanged } }) => (
          <span className="admin-menu">
            {appChanged && (
              <span>
                Revert{" "}
                <FA
                  name="undo"
                  onClick={this.props.handleRevertChanges}
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
            {appChanged && (
              <span>
                Save{" "}
                <FA
                  name="save"
                  onClick={this.props.handleUpdateApp}
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
            <span>
              Edit{" "}
              <FA
                name={"toggle-" + (this.state.on ? "on" : "off")}
                onClick={this.handleToogle}
                style={{ cursor: "pointer" }}
              />
            </span>
          </span>
        )}
      </AppConsumer>
    ) : null;
  }
}
