import React, { SyntheticEvent } from "react";
import { Element as ReactElement } from "react";
import { Input } from "reactstrap";
import Lightbox from "react-image-lightbox";
import axios from "axios";
import deepDiff from "deep-diff";
import update from "immutability-helper";
// @ts-ignore
import { ViewWrapper } from "airr-react";
import { Generic } from "fp-ui-components-react";
import Auth from "volvo-ping-auth-helper";
import { initAppInsights } from "../../services/AppInsights";
import {
  DevOpsRepo,
  App,
  GalleryImg,
  GalleryImgToRender,
  NewImg,
} from "../../resources/Types";

import { Util } from "../../resources/Util";
import { User } from "../../resources/User";

import AppInputField from "../../ui/AppInputField";
import AppFilterField from "../../ui/AppFilterField";
import {
  apiUrl,
  Languages,
  ManagedBy,
  getAuthHeader,
  downloadAndroidUrl,
  AppCategory,
} from "../../resources/Config";

import {
  DATA_APP_UPDATE,
  DATA_APP_UPDATED,
  DATA_APP_CHANGED,
  APP_DATA_REVERT_CHANGES,
} from "../../resources/AppEvents";
import { pictureScaleUrl } from "../../resources/Config";
import AppManager from "../../providers/AppManager";
import * as Device from "../../utils/Device";
import { AppStatuses, Platforms, Devices } from "../../resources/Config";

import { InitialState } from "../../providers/Types";
import AppDetailField from "../../ui/AppView/AppDetailField";
import NavbarAdminMenu from "../../ui/AppView/NavbarAdminMenu";
import AddRepoModal from "../../ui/AppView/AddRepoModal";
import {
  Header,
  Divider,
  GooglePlayBadge,
  AppStoreBadge,
  InTuneBadge,
  AccentDivider,
  SectionCollapsible,
  TopSpacing,
  SmallButton,
} from "../../ui/Common";
import { getAppImage } from "../../utils/Image";
import ImageThumbnail from "../../ui/Common/ImageThumbnail/ImageThumbnail";
import * as Utils from "../../ui/Utils";
import "../../../css/views/AppView.css";
import ManagedByFieldDescription from "../../ui/Common/Fields/ManagedByFieldDescription";

const FA = Generic.FontAwesome;
const IMG_ARTWORK_TYPE = "artworks";
const IMG_SCREENSHOT_TYPE = "screenshots";
const appInsights = initAppInsights();

type UpdateAppCoverProps = {
  hide: boolean;
  msg1: string | ReactElement<"div"> | ReactElement<"span">;
  msg2: string | ReactElement<"div"> | ReactElement<"span">;
};
const UpdateAppCover = function ({ hide, msg1, msg2 }: UpdateAppCoverProps) {
  return (
    <Generic.UIFadingCover hide={hide}>
      <Generic.Loader2 />
      {msg1 && <div className="text-center">{msg1}</div>}
      {msg2 && <div className="text-center">{msg2}</div>}
    </Generic.UIFadingCover>
  );
};
UpdateAppCover.defaultProps = {
  hide: false,
  msg1: "",
  msg2: "",
};

type Props = {
  title: null;
  app: null;
  appIndex: null;
  disableGUI: () => void;
  enableGUI: () => void;
  getAppState: () => InitialState;
  appManager: AppManager;
};
type State = {
  photoIndex: number;
  isOpen: boolean;
  openGalleryType: string | null;
  allImagesLoaded: boolean;
  addedImagesDataUrls: {};
  videoClass: string;
  app: App | null;
  showNewRepoModal: boolean;
  tempDevOpsRepoType: string;
  tempDevOpsRepoLink: string;
  tempDevOpsRepoIndex?: number;
  downloadingAndroid: boolean;
};

export const viewName = "app-view";
export default class AppView extends ViewWrapper<Props, State> {
  state = {
    photoIndex: 0,
    isOpen: false,
    openGalleryType: null,
    allImagesLoaded: false,
    addedImagesDataUrls: {},
    videoClass: "",
    app: null,
    showNewRepoModal: false,
    tempDevOpsRepoType: "",
    tempDevOpsRepoLink: "",
    downloadingAndroid: false,
  };

  imagesToLoad = 0;
  loadedImagesCount = 0;

  isAndroid = Device.isAndroidDevice();
  isIos = Device.isIosDevice();
  isMobile = Boolean(this.isAndroid || this.isIos);
  isDesktop = !this.isMobile;

  constructor(props: {}) {
    super(props);

    if (User.isAdmin()) {
      this.state.app = Object.assign({}, this.props.app);
    }

    this.initGalleryImages();
  }

  viewAfterActivation() {
    if (User.isAdmin()) {
      this.setState({ app: Object.assign({}, this.props.app) });
    }

    this.props.scrollToTop();
    appInsights.trackEvent({
      name: "App view opened",
      properties: {
        id: `${this.props.app.id}`,
        name: `${this.props.app.name}`,
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.app && User.isAdmin() && !this.state.app) {
      this.setState({ app: Object.assign({}, this.props.app) });
    }
  }

  //updatedAppIndex may differ from app.id when new app is
  //being added
  triggerAppDataUpdatedEvent(app: App) {
    this.props.appManager.triggerEvent(DATA_APP_UPDATED, {
      updatedAppIndex: this.props.appIndex,
      appChanged: false,
      apps: update(this.props.getAppState().apps, {
        [this.props.appIndex]: {
          $set: app,
        },
      }),
    });
  }


  toggleRepoModal = (repoType: string = "", repoLink: string = "", index?: number = undefined) => {
    this.setState({
      tempDevOpsRepoType: repoType,
      tempDevOpsRepoLink: repoLink,
      tempDevOpsRepoIndex: index,
      showNewRepoModal: !this.state.showNewRepoModal,
    });
  };

  handleRepoSave = (repo: DevOpsRepo): void => {
    let updates = {
      app: update(this.state.app, {
        devOpsRepos: {
          $push: [repo],
        },
      }),
    };
    if (this.state.tempDevOpsRepoIndex !== undefined) {
      updates = {
        app: update(this.state.app, {
          devOpsRepos: {
            [this.state.tempDevOpsRepoIndex]: { $set: repo },
          },
        }),
      };
    }

    this.setState(updates, () => {
      this.toggleRepoModal();
      this.dispatchAppModification();
    });
  };

  startDataUpdateTS: number;
  DataAppUpdateListener = () => {
    if (this.state.app) {
      const formData = this.createAppUpdateFormData(this.state.app);

      this.startDataUpdateTS = Date.now();
      this.props.disableGUI(<UpdateAppCover msg1="Updating data" msg2="...please wait..." />);

      //make a request to the backend with update
      axios
        .post(apiUrl, formData, { headers: getAuthHeader(Auth.token) })
        .then((res) => {
          this.props.disableGUI(
            <UpdateAppCover
              msg1="Update success"
              msg2={
                <span style={{ fontSize: "2rem" }} role="img" aria-label="success">
                  &#128588;
                </span>
              }
            />,
          );

          const app = res.data;
          //finally on success trigger that app was updated
          this.triggerAppDataUpdatedEvent(app);
          //update images values from local data-urls to uploaded storage urls
          this.setState({
            app: update(this.state.app, {
              screenshots: { $set: app.screenshots },
              artworks: { $set: app.artworks },
            }),
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.disableGUI(
            <UpdateAppCover
              msg1="Error occured"
              msg2="...please try again or investigate console for more info..."
            />,
          );
          //on failure also trigger that app was updated
          //it will close the update cover
          this.props.appManager.triggerEvent(DATA_APP_UPDATED);
        });
    }
  };

  DataAppUpdatedListener = () => {
    const diff = Date.now() - this.startDataUpdateTS;

    if (diff < 2500) {
      setTimeout(this.destroyAppUpdateCover, 2500 - diff);
    } else {
      this.destroyAppUpdateCover();
    }
  };

  registerEventListeners() {
    this.props.appManager.addEventListener(DATA_APP_UPDATE, this.DataAppUpdateListener);
    this.props.appManager.addEventListener(DATA_APP_UPDATED, this.DataAppUpdatedListener);
    this.props.appManager.addEventListener(APP_DATA_REVERT_CHANGES, this.RevertChangesListener);
  }

  removeEventListeners() {
    this.props.appManager.removeEventListener(DATA_APP_UPDATE, this.DataAppUpdateListener);
    this.props.appManager.removeEventListener(DATA_APP_UPDATED, this.DataAppUpdatedListener);
    this.props.appManager.removeEventListener(APP_DATA_REVERT_CHANGES, this.RevertChangesListener);
  }

  componentDidMount() {
    this.registerEventListeners();
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.removeEventListeners();
  }

  destroyAppUpdateCover = () => {
    this.props.disableGUI(<UpdateAppCover hide={true} />);

    setTimeout(() => {
      if (!this.unmounted) {
        this.props.enableGUI();
      }
    }, 300);
  };

  createAppUpdateFormData(app: App) {
    const formData = new FormData();
    app.brand = app.brand.trim();
    app.name = app.name.trim();
    app.apmID = Number(app.apmID);
    const appObj = Object.assign({}, app, {
      artworks: Object.entries(app.artworks).reduce(
        (prev, [key, value]: [string, any | NewImg]) => {
          if (typeof value === "string") {
            prev[key] = value;
          } else if (value.img_id) {
            formData.append("newimg-artwork" + key, value.file);
          }

          return prev;
        },
        {},
      ),
      screenshots: Object.entries(app.screenshots).reduce((prev, [key, value]) => {
        if (Array.isArray(value)) {
          prev[key] = value.filter((img: any | NewImg, index) => {
            if (typeof img !== "string") {
              const file: File = img.file;
              formData.append("newimg-screenshot-" + key + "-" + index, file);
            }
            return typeof img === "string";
          });
        }

        return prev;
      }, {}),
    });

    formData.append("app", JSON.stringify(appObj));
    return formData;
  }

  onImageLoad = () => {
    this.loadedImagesCount++;

    if (this.imagesToLoad && this.loadedImagesCount === this.imagesToLoad && !this.unmounted) {
      this.setState({
        allImagesLoaded: true,
      });
    }
  };

  appendImageToLoad(src: string) {
    const image = new Image();
    image.src = src;
    image.onload = this.onImageLoad;
    image.onerror = this.onImageLoad;
    this.imagesToLoad++;
  }

  initGalleryImages() {
    if (this.props.app) {
      if (this.props.app.artworks && this.props.app.artworks["512"]) {
        this.appendImageToLoad(
          this.getThumbnailImageSrc(
            this.props.app.artworks["512"],
            "250",
            "artwork",
            "512",
            this.props.app.id,
          ),
        );
      }

      if (this.props.app[IMG_SCREENSHOT_TYPE].common) {
        this.props.app[IMG_SCREENSHOT_TYPE].common.forEach((href, index) => {
          this.appendImageToLoad(
            this.getThumbnailImageSrc(
              href,
              "540",
              "screenshots",
              "common",
              this.props.app.id,
              index,
            ),
          );
        });
      }

      if (this.props.app[IMG_SCREENSHOT_TYPE].ipad) {
        this.props.app[IMG_SCREENSHOT_TYPE].ipad.forEach((href, index) => {
          this.appendImageToLoad(
            this.getThumbnailImageSrc(href, "540", "screenshots", "ipad", this.props.app.id, index),
          );
        });
      }
    }
  }

  getThumbnailImageSrc(
    backgroundSrc: string | null,
    targetWidth: string,
    type: string,
    key: string,
    appId: number,
    index: number | null = null,
  ) {
    // hacky fix I know, but it works fine. Just to render proper url when scaling/displaying
    if (type === IMG_ARTWORK_TYPE) {
      type = "artwork";
    }

    const scaleKey =
      type === "screenshots" ? `${type}-${key}-${index ? index : ""}` : `${type}${key}`;

    return `${pictureScaleUrl}?url=${
      backgroundSrc ? backgroundSrc : ""
    }&width=${targetWidth}&appId=${appId}&key=${scaleKey}`;
  }

  renderImage({ src, type, key, index, newImg }: GalleryImgToRender, admin: boolean = false) {
    const id: string = src ? src : newImg ? newImg.img_id : "";
    const backgroundSrc: string = src
      ? src
      : newImg && this.state.addedImagesDataUrls[newImg.img_id]
        ? this.state.addedImagesDataUrls[newImg.img_id]
        : "";
    let thumbnailImageSrc = "";

    const targetWidth = type === IMG_SCREENSHOT_TYPE ? "540" : "250";

    thumbnailImageSrc = this.getThumbnailImageSrc(
      backgroundSrc,
      targetWidth,
      type,
      key,
      this.props.app.id,
      index,
    );

    const backgroundStyle =
      thumbnailImageSrc || backgroundSrc
        ? {
            backgroundImage: `url("${thumbnailImageSrc ? thumbnailImageSrc : backgroundSrc}")`,
          }
        : null;

    return (
      <div className={"col-6 col-md-4 col-lg-3 type-" + type + " key-" + key} key={id}>
        <div
          className="img"
          onClick={(e) => {
            e.preventDefault();

            this.setState({
              isOpen: true,
              openGalleryType: type,
              photoIndex: index,
            });
          }}
          style={backgroundStyle}
          id={id}
        >
          {admin && this.props.editState && (
            <span onClick={(e) => this.handleImageRemove(e, type, key, src, newImg)}>
              <FA name="trash" />
            </span>
          )}
        </div>
      </div>
    );
  }

  handleImageRemove = (
    e: SyntheticEvent<HTMLSpanElement>,
    type: string,
    key: string,
    src?: string,
    newImg?: NewImg,
  ) => {
    e.stopPropagation();

    if (type === IMG_ARTWORK_TYPE) {
      this.setState(
        {
          app: update(this.state.app, {
            [type]: {
              [key]: { $set: "" },
            },
          }),
        },
        this.dispatchAppModification,
      );
    } else if (type === IMG_SCREENSHOT_TYPE && this.state.app) {
      let index;

      if (src) {
        index = this.state.app.screenshots[key].indexOf(src);
      } else {
        index = this.state.app.screenshots[key].findIndex(
          (item) => typeof item !== "string" && newImg && item.img_id === newImg.img_id,
        );
      }

      this.setState(
        {
          app: update(this.state.app, {
            [type]: {
              [key]: {
                $splice: [[index, 1]],
              },
            },
          }),
        },
        this.dispatchAppModification,
      );
    }
  };

  getImageObjectConf(imgSrc: string | NewImg, type: string, key: string): GalleryImg {
    return {
      src: typeof imgSrc === "string" ? imgSrc : undefined,
      type,
      key,
      newImg: typeof imgSrc !== "string" ? imgSrc : undefined,
    };
  }

  getAppArtworks(): GalleryImg[] {
    const images: GalleryImg[] = [];
    let source = User.isAdmin() ? "state" : "props";

    if (this["state"].app && this["props"].app && this["state"].app.id !== this["props"].app.id) {
      source = "props";
    }

    if (
      this[source].app &&
      this[source].app[IMG_ARTWORK_TYPE] &&
      this[source].app[IMG_ARTWORK_TYPE]["512"]
    ) {
      images.push(
        this.getImageObjectConf(this[source].app[IMG_ARTWORK_TYPE]["512"], IMG_ARTWORK_TYPE, "512"),
      );
    }

    return images;
  }

  getAppScreenshots(): GalleryImg[] {
    const images: GalleryImg[] = [];
    let source = User.isAdmin() ? "state" : "props";

    if (this["state"].app && this["props"].app && this["state"].app.id !== this["props"].app.id) {
      source = "props";
    }

    if (this[source].app && this[source].app[IMG_SCREENSHOT_TYPE]) {
      if (this[source].app[IMG_SCREENSHOT_TYPE].common) {
        this[source].app[IMG_SCREENSHOT_TYPE].common.forEach((imgSrc: string | NewImg): void => {
          images.push(this.getImageObjectConf(imgSrc, IMG_SCREENSHOT_TYPE, "common"));
        });
      }

      if (this[source].app[IMG_SCREENSHOT_TYPE].ipad) {
        this[source].app[IMG_SCREENSHOT_TYPE].ipad.forEach((imgSrc: string | NewImg): void => {
          images.push(this.getImageObjectConf(imgSrc, IMG_SCREENSHOT_TYPE, "ipad"));
        });
      }
    }

    return images;
  }

  getData(key: string) {
    return this.props.getAppState()[key];
  }

  getAppData(key: string) {
    const source = User.isAdmin() ? "state" : "props";
    return this[source].app ? this[source].app[key] : "";
  }

  handleAppDescriptionChange(type: string) {
    return (value: string) => {
      this.setState(
        {
          app: update(this.state.app, {
            description: {
              [type]: {
                $set: value,
              },
            },
          }),
        },
        this.dispatchAppModification,
      );
    };
  }

  handleAppPropertyChange(propertyName: string): (val: string | string[]) => void {
    return (value: string | string[]) => {
      this.setState(
        {
          app: update(this.state.app, {
            [propertyName]: {
              $set: value,
            },
          }),
        },
        this.dispatchAppModification,
      );
    };
  }

  dispatchAppModification() {
    this.props.appManager.triggerEvent(DATA_APP_CHANGED, {
      appChanged: this.isAppModified(),
    });
  }

  isAppModified() {
    const diff = deepDiff(this.props.app, this.state.app);
    return Boolean(Array.isArray(diff) && diff.length);
  }

  revertAllChanges() {
    this.setState(
      {
        app: Object.assign({}, this.props.app),
      },
      () => {
        this.props.appManager.triggerEvent(DATA_APP_CHANGED, {
          appChanged: false,
        });
      },
    );
  }

  RevertChangesListener = () => {
    this.revertAllChanges();
  };

  handleAddImageBtnClick = (type: string, key: string) => {
    const input = document.createElement("input");
    input.setAttribute("accept", "image/*");

    if (type === IMG_SCREENSHOT_TYPE) {
      input.setAttribute("multiple", "true");
    }

    input.setAttribute("type", "file");
    input.onchange = () => {
      const files = Array.from(input.files);

      if (type === IMG_SCREENSHOT_TYPE) {
        this.setState(
          {
            app: update(this.state.app, {
              [IMG_SCREENSHOT_TYPE]: {
                [key]: {
                  $push: files.map(
                    (file): NewImg => ({
                      img_id: window.btoa(file.name + file.size + Util.getRandString(8)),
                      file,
                    }),
                  ),
                },
              },
            }),
          },
          this.dispatchAppModification,
        );
      } else if (type === IMG_ARTWORK_TYPE) {
        const file: File = files[0];
        const img_id: string = window.btoa(file.name + file.size + Util.getRandString(8));
        const newImg: NewImg = { img_id, file };

        this.setState(
          {
            app: update(this.state.app, {
              [IMG_ARTWORK_TYPE]: {
                [key]: {
                  $set: newImg,
                },
              },
            }),
          },
          this.dispatchAppModification,
        );
      }
    };
    input.click();
  };

  renderGallery(gallery: GalleryImg[], startIndex: number = 0): ReactElement<"div">[] {
    return gallery.map(({ src, type, key, newImg }: GalleryImg, index: number) => {
      if (newImg && !this.state.addedImagesDataUrls[newImg.img_id]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // get loaded data and render thumbnail.

          this.setState({
            addedImagesDataUrls: update(this.state.addedImagesDataUrls, {
              [newImg.img_id]: {
                $set: e.target.result,
              },
            }),
          });
        };
        // read the image file as a data URL.
        reader.readAsDataURL(newImg.file);
      }

      return type === IMG_SCREENSHOT_TYPE || (type === IMG_ARTWORK_TYPE && key === "512")
        ? this.renderImage(
            {
              index: index + startIndex,
              src,
              type,
              key,
              newImg,
            },
            User.isAdmin(),
          )
        : null;
    });
  }

  renderTextProperty(name: string, label: string, placeholder: string) {
    const value = this.getAppData(name);

    return this.props.editState === true ? (
      <AppDetailField label={label}>
        <AppInputField
          placeholder={placeholder}
          inEditState={this.props.editState}
          value={value === null ? "" : value}
          onChange={this.handleAppPropertyChange(name)}
        ></AppInputField>
      </AppDetailField>
    ) : (
      <span>{value}</span>
    );
  }

  renderSpanProperty(name: string, placeholder: string, inputType: string = "text") {
    const value = this.getAppData(name);

    return (
      <>
        <AppInputField
          placeholder={placeholder}
          inEditState={this.props.editState}
          inputType={inputType}
          value={value === null ? "" : value}
          onChange={this.handleAppPropertyChange(name)}
        >
          {(val) => <span>{val}</span>}
        </AppInputField>
      </>
    );
  }

  renderTextareaProperty(propertyName: string, placeholder: string) {
    return (
      <AppInputField
        placeholder={placeholder}
        inEditState={this.props.editState}
        tagType="textarea"
        value={this.getAppData(propertyName)}
        onChange={this.handleAppPropertyChange(propertyName)}
      >
        {(val) => <span className="description">{val}</span>}
      </AppInputField>
    );
  }

  renderDescriptionProperty(type: string, placeholder: string) {
    return (
      <AppInputField
        placeholder={placeholder}
        inEditState={this.props.editState}
        tagType="textarea"
        value={this.getAppData("description")[type]}
        onChange={this.handleAppDescriptionChange(type)}
      >
        {(val) =>
          typeof val === "string" && (
            <div className="description">
              {val.split("\n").map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          )
        }
      </AppInputField>
    );
  }

  renderLinkProperty(
    name: string,
    renderChildFunc?: (value: any) => any,
    placeholder?: string = "",
  ) {
    return (
      <AppInputField
        placeholder={placeholder || "enter http link"}
        inEditState={this.props.editState}
        inputType="text"
        value={this.getAppData(name)}
        onChange={this.handleAppPropertyChange(name)}
      >
        {renderChildFunc
          ? renderChildFunc
          : (val) =>
              Boolean(val) && (
                <a target="_blank" rel="noopener noreferrer" href={val}>
                  {val}
                </a>
              )}
      </AppInputField>
    );
  }

  handleVideoOnLoadedMetadata = (e: SyntheticEvent<HTMLVideoElement>) => {
    if (
      e.currentTarget.videoWidth &&
      e.currentTarget.videoHeight &&
      e.currentTarget.videoWidth < e.currentTarget.videoHeight
    ) {
      this.setState({
        videoClass: "portrait",
      });
    }
  };

  renderVideoProperty(name: string) {
    return (
      <AppInputField
        placeholder="enter http link to mp4 video"
        inEditState={this.props.editState}
        inputType="text"
        value={this.getAppData(name)}
        onChange={this.handleAppPropertyChange(name)}
        extraClass={"mb-3"}
      >
        {(val: any) => (
          <div>
            {Boolean(val) && (
              <div className="video-ctn">
                <video
                  controls
                  onLoadedMetadata={(e) => this.handleVideoOnLoadedMetadata(e)}
                  className={this.state.videoClass}
                >
                  <source src={val} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        )}
      </AppInputField>
    );
  }

  renderSelectProperty(name: string, label: string, options: string[]) {
    let value = "";
    if (this.state.app) {
      value = this.state.app[name];
    }

    if (value === null) {
      value = "";
    }

    const isEmpty = value === "";

    return this.props.editState === true ? (
      <Input
        type="select"
        value={value}
        onChange={(event) => this.handleAppPropertyChange(name)(event.target.value)}
      >
        {isEmpty && <option value="">Select ...</option>}
        {options.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </Input>
    ) : (
      <span>{value}</span>
    );
  }

  renderMultiselectProperty(name: string) {
    const type = name === "region" ? "multiselect" : "multiautocomplete";
    return this.renderFilterProperty(name, type, (values) => <span>{values.join(", ")}</span>, {
      hideLabel: true,
    });
  }

  renderFilterProperty(
    name: string,
    fieldType: "multiselect" | "multiautocomplete" | "autocomplete",
    childFunc?: (value: any) => any,
    props?: {},
  ) {
    const childRenderProp = childFunc
      ? childFunc
      : fieldType === "multiselect" || fieldType === "multiautocomplete"
        ? (i: string[] | string) =>
            Array.isArray(i) && i.map((item) => <span key={item}>{item}</span>) //default items map function
        : null;

    const selectedData = this.getAppData(name);
    const selected = selectedData
      ? selectedData
      : fieldType === "multiselect" || fieldType === "multiautocomplete"
        ? []
        : "";
    const items =
      name === "languageSupport"
        ? Languages
        : name === "platform"
          ? Platforms
          : name === "device"
            ? Devices
            : this.getData(name);

    return (
      <AppFilterField
        inEditState={this.props.editState}
        name={name}
        selected={selected}
        fieldType={fieldType}
        items={items}
        onChange={this.handleAppPropertyChange(name)}
        props={props}
      >
        {childRenderProp}
      </AppFilterField>
    );
  }

  getImageSrc(img: GalleryImg): string {
    return img.src
      ? img.src
      : img.newImg && img.newImg.img_id && this.state.addedImagesDataUrls[img.newImg.img_id]
        ? this.state.addedImagesDataUrls[img.newImg.img_id]
        : "";
  }

  addLightbox(
    isOpen: boolean,
    mainSrc: string | undefined,
    nextSrc: string | undefined,
    prevSrc: string | undefined,
    onMovePrevRequest: (() => any) | undefined,
    onMoveNextRequest: (() => any) | undefined,
  ) {
    if (isOpen) {
      return (
        <Lightbox
          mainSrc={mainSrc}
          nextSrc={nextSrc}
          prevSrc={prevSrc}
          onCloseRequest={() =>
            this.setState({
              isOpen: false,
            })
          }
          onMovePrevRequest={onMovePrevRequest}
          onMoveNextRequest={onMoveNextRequest}
        />
      );
    }
    return null;
  }

  getAppDetailFields(artworksGallery: GalleryImg[], screenshotsGallery: GalleryImg[]) {
    const commonScreenshots = screenshotsGallery.filter((img: GalleryImg) => img.key === "common");
    const ipadScreenshots = screenshotsGallery.filter((img: GalleryImg) => img.key === "ipad");

    const spanProperties = {
      REVERSED_DOMAIN_STYLE: "reversed domain style",
      YYYY_MM_DD: "YYYY-MM-DD",
    };

    return (
      <div className="row">
        <div className="col-12">
          <TopSpacing>
            <SectionCollapsible title="Volvo details">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Region">
                    {this.renderMultiselectProperty("region")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Delivery leader">
                    {this.renderMultiselectProperty("teamLeader")}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Solution leader">
                    {this.renderMultiselectProperty("solutionLeader")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Business process">
                    {this.renderMultiselectProperty("businessProcess")}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Target group">
                    {this.renderMultiselectProperty("targetGroup")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Supported languages">
                    {this.renderMultiselectProperty("languageSupport")}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Business key user">
                    {this.renderMultiselectProperty("businessKeyUser")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="BSPM">
                    {this.renderMultiselectProperty("BSPM")}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="APM ID">
                    {this.renderSpanProperty("apmID", "APM ID", "number")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Status">
                    {this.renderSelectProperty("appStatus", "Status", AppStatuses)}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AppDetailField label="Faros groups">
                    {this.renderSpanProperty("farosGroups", "Faros Groups")}
                  </AppDetailField>
                </div>
              </div>
            </SectionCollapsible>
          </TopSpacing>
        </div>
        <div className="col-12">
          <TopSpacing>
            <SectionCollapsible title="Platform details">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="App Category">
                    {this.renderSelectProperty("appCategory", "App Category", AppCategory)}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="iOS Bundle ID">
                    {this.renderSpanProperty("bundleID", spanProperties.REVERSED_DOMAIN_STYLE)}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="iOS Last version">
                    {this.renderSpanProperty("latestVersion", "x.x.x")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="iOS Last update">
                    {this.renderSpanProperty("statusChangeDate", spanProperties.YYYY_MM_DD)}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {Boolean(this.isDesktop || this.isIos) &&
                    this.renderAppStoreRow("appleStoreLink", "Apple Store link")}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Android Bundle ID">
                    {this.renderSpanProperty("androidBundleID")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Android Last version">
                    {this.renderSpanProperty("androidLatestVersion", "x.x.x")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Android Last update">
                    {this.renderSpanProperty("androidChangeDate")}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Intune Bundle ID">
                    {this.renderSpanProperty(
                      "intuneBundleID",
                      spanProperties.REVERSED_DOMAIN_STYLE,
                    )}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Intune Last version">
                    {this.renderSpanProperty("intuneLatestVersion", "x.x.x")}
                  </AppDetailField>
                </div>
                <div className="col-12 col-lg-6">
                  <AppDetailField label="Intune Last update">
                    {this.renderSpanProperty("intuneChangeDate", spanProperties.YYYY_MM_DD)}
                  </AppDetailField>
                </div>
              </div>
              <div className="row">
                <div className="col-12">{this.renderAppLinkRow("webLink", "Web Link")}</div>
              </div>
            </SectionCollapsible>
          </TopSpacing>
        </div>
        <div className="col-12">
          <TopSpacing>
            <SectionCollapsible title="Installation details">
              <div className="row">
                <div className="col-12 col-lg-6">{this.renderAppInstallLinksRow(false)}</div>
                <div className="col-12 col-lg-6">{this.renderAppInstallLinksRow(true)}</div>
              </div>
            </SectionCollapsible>
          </TopSpacing>
        </div>
        <div className="col-12">
          <TopSpacing>
            <SectionCollapsible title="Multimedia">
              <div className="row">
                <div className="col-12">
                  {(this.props.app.videoLink || User.isAdmin()) &&
                    this.renderVideoProperty("videoLink")}
                </div>
                <div className="col-12">
                  {this.renderGalleryCtn(
                    artworksGallery,
                    screenshotsGallery,
                    commonScreenshots,
                    ipadScreenshots,
                  )}

                  {this.renderAdminAddScreenshots()}
                </div>
              </div>
            </SectionCollapsible>
          </TopSpacing>
        </div>
        <div className="col-12">
          <TopSpacing></TopSpacing>
        </div>
      </div>
    );
  }

  getAppDetails(app: any, artworksGallery: GalleryImg[], screenshotsGallery: GalleryImg[]) {
    const appImage = getAppImage(app);

    return (
      <div className="row">
        <div className="col-12 col-md-3">
          <ImageThumbnail url={appImage} isPromo={true} />
        </div>
        <div className="col-12 col-md-9">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="app-name">
                {this.renderTextProperty("name", "app name", "fill in application name")}
              </div>
              <div className="app-brand">
                {this.renderFilterProperty("brand", "autocomplete", (value) => (
                  <span>{value}</span>
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-end">
              <GooglePlayBadge
                bundleId={app.androidBundleID}
                link={app.playStoreLink}
                rating={app.androidAverageUserRating}
                count={app.androidUserRatingCount}
              />
              <AppStoreBadge
                link={app.appleStoreLink}
                rating={app.iOSAverageUserRating}
                count={app.iOSUserRatingCount}
              />
              <InTuneBadge bundleId={app.intuneBundleID} />
            </div>
            <div className="col-12">
              <AccentDivider />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              {this.renderFilterProperty("platform", "multiselect", (platforms) => (
                <Utils.Platforms platforms={platforms} useBiggerIcons={true} />
              ))}
            </div>
            <div className="col-12 col-lg-8">
              {this.renderFilterProperty("device", "multiselect", (devices) => (
                <Utils.Devices devices={devices} />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TopSpacing>
                <SectionCollapsible title="About">
                  {this.renderDescriptionProperty("common", "provide common app description")}
                  {this.renderDescriptionProperty("store", "provide store app description")}
                </SectionCollapsible>
              </TopSpacing>
            </div>
          </div>
          {this.getAppDetailFields(artworksGallery, screenshotsGallery)}
        </div>
      </div>
    );
  }

  getAdminOrMCoEUserSection() {
    if (User.isAdmin() || User.isMCoEUser()) {
      return (
        <div className="app-view-internal">
          <div className="container">
            <div className="row">
              <div className="col-12 internal-header">
                <TopSpacing>
                  Internal Fields <span>(visible for Admins & MCoE Users only)</span>
                  <AccentDivider />
                </TopSpacing>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Delivery Manager">
                      {this.renderMultiselectProperty("deliveryManager")}
                    </AppDetailField>
                  </div>
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Domain Manager">
                      {this.renderMultiselectProperty("domainManager")}
                    </AppDetailField>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Security Groups">
                      {this.renderSpanProperty("securityGroups", "")}
                    </AppDetailField>
                  </div>
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Other App names">
                      {this.renderSpanProperty("otherAppNames", "")}
                    </AppDetailField>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Internal comments">
                      {this.renderTextareaProperty(
                        "internalComments",
                        "for internal info including WBS elements for runtime support",
                      )}
                    </AppDetailField>
                  </div>
                  <div className="col-12 col-lg-6">
                    <AppDetailField label="Extra comments">
                      {this.renderTextareaProperty(
                        "extraComments",
                        "for anything else you may need later on",
                      )}
                    </AppDetailField>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <AppDetailField label="Managed By">
                  {this.renderSelectProperty("managedBy", "Managed By", ManagedBy)}
                  <ManagedByFieldDescription />
                </AppDetailField>
              
                <div className="pt-1 pb-1">
                  <dt>Repos: </dt>
                  {this.state.app &&
                    this.state.app.devOpsRepos &&
                    this.state.app.devOpsRepos.map((repo, index) => (
                      <div key={repo.type} className="pb-1 ">
                        <span>{repo.type}: </span>
                        <a href={repo.link} target="_blank" rel="noopener noreferrer">
                          Visit
                        </a>

                        <FA
                          name="pencil-alt"
                          onClick={() => this.toggleRepoModal(repo.type, repo.link, index)}
                        />
                      </div>
                    ))}
                  {User.isAdmin() && (
                    <SmallButton text="Add new repo" onClick={() => this.toggleRepoModal()} />
                  )}
                  <AddRepoModal
                    show={this.state.showNewRepoModal}
                    closeForm={this.toggleRepoModal}
                    handleSave={this.handleRepoSave}
                    repoType={this.state.tempDevOpsRepoType}
                    repoLink={this.state.tempDevOpsRepoLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  content() {
    if (this.props.app) {
      const { isOpen, photoIndex, openGalleryType } = this.state;
      const screenshotsGallery = this.getAppScreenshots();
      const artworksGallery = this.getAppArtworks();

      let mainSrc, nextSrc, prevSrc, onMoveNextRequest, onMovePrevRequest;

      if (isOpen && openGalleryType) {
        const openedGallery =
          openGalleryType === IMG_ARTWORK_TYPE ? artworksGallery : screenshotsGallery;
        const nextIndex = (photoIndex + 1) % openedGallery.length;
        const prevIndex = (photoIndex + openedGallery.length - 1) % openedGallery.length;

        mainSrc = this.getImageSrc(openedGallery[photoIndex]);
        nextSrc = this.getImageSrc(openedGallery[nextIndex]);
        prevSrc = this.getImageSrc(openedGallery[prevIndex]);

        onMovePrevRequest = () =>
          this.setState({
            photoIndex: (photoIndex + openedGallery.length - 1) % openedGallery.length,
          });
        onMoveNextRequest = () =>
          this.setState({
            photoIndex: (photoIndex + 1) % openedGallery.length,
          });
      }

      const { app } = this.props;

      return (
        <div>
          {this.addLightbox(
            isOpen,
            mainSrc,
            nextSrc,
            prevSrc,
            onMovePrevRequest,
            onMoveNextRequest,
          )}

          <div className="app-view">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="top-bar">
                    <div onClick={this.props.handleBackButton} className="back-button">
                      <FA name="chevron-left" />
                      <span>Back to results</span>
                    </div>
                    {User.isAdmin() && (
                      <NavbarAdminMenu
                        handleEditToogle={this.props.handleEditToogle}
                        handleRevertChanges={this.props.handleRevertChanges}
                        handleUpdateApp={this.props.handleUpdateApp}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TopSpacing>
                    <Header text="App details" />
                    <Divider />
                  </TopSpacing>
                </div>
              </div>

              {this.getAppDetails(app, artworksGallery, screenshotsGallery)}
            </div>
          </div>

          {this.getAdminOrMCoEUserSection()}
        </div>
      );
    } else {
      return (
        <div className="no-app-found">
          <div className="text-center">
            Sorry but no app can be shown under given address
            <br />
            <a href="/" title="Home page">
              Go to home page
            </a>
          </div>
        </div>
      );
    }
  }

  renderAppLinkRow(propName: string, label: string) {
    return (
      Boolean(this.props.app[propName] || User.isAdmin()) && (
        <div className="row links">
          <div className="col-12">
            <dl>
              {this.props.editState && <dt>{label}:</dt>}
              <dd>{this.renderLinkProperty(propName)}</dd>
            </dl>
          </div>
        </div>
      )
    );
  }

  renderAppStoreRow(propName: string, label: string) {
    return (
      (Boolean(this.props.app[propName]) || User.isAdmin()) && (
        <div className="row links">
          <div className="col-12">
            <dl>
              {this.props.editState && <dt>{label}:</dt>}
              <dd>
                {this.renderLinkProperty(propName, (val) => (
                  <span />
                ))}
              </dd>
            </dl>
          </div>
        </div>
      )
    );
  }

  renderGalleryCtn(
    artworksGallery: GalleryImg[],
    screenshotsGallery: GalleryImg[],
    commonScreenshots: GalleryImg[],
    ipadScreenshots: GalleryImg[],
  ) {
    return (
      <div className="gallery-ctn">
        {Boolean(artworksGallery.length) && (
          <div className="row">
            <div className="col-12">
              <h5>Icon</h5>
            </div>
            {this.renderGallery(artworksGallery)}
          </div>
        )}
        {this.renderAdminAddIcons()}
        {Boolean(screenshotsGallery.length) && (
          <div className="row">
            <div className="col-12">
              <h5>Screenshots</h5>
            </div>

            {Boolean(commonScreenshots.length) && (
              <div className="col-12">
                <h6>Phone</h6>
                <div className="row">{this.renderGallery(commonScreenshots)}</div>
              </div>
            )}

            {Boolean(ipadScreenshots.length) && (
              <div className="col-12">
                <h6>Tablet</h6>
                <div className="row">
                  {this.renderGallery(ipadScreenshots, commonScreenshots.length)}
                </div>
              </div>
            )}
          </div>
        )}

        {Boolean(this.imagesToLoad) && !this.state.allImagesLoaded && (
          <div className="loader-canvas">
            <Generic.Loader />
          </div>
        )}
      </div>
    );
  }

  renderAdminAddIcons() {
    return (
      User.isAdmin() && (
        <div className="row mt-1 mb-5">
          <div className="col-12">
            <button
              className="btn btn-dark btn-sm"
              onClick={() => this.handleAddImageBtnClick(IMG_ARTWORK_TYPE, "512")}
            >
              add icon (512px)
            </button>
          </div>
        </div>
      )
    );
  }

  renderAdminAddScreenshots() {
    return (
      User.isAdmin() && (
        <div className="row mt-1 mb-5">
          <div className="col-12">
            <div>
              <b>Add new screenshots:</b>
            </div>

            <button
              className="btn btn-dark btn-sm"
              onClick={() => this.handleAddImageBtnClick(IMG_SCREENSHOT_TYPE, "common")}
            >
              Phone
            </button>
            <button
              className="btn btn-dark btn-sm ml-3 mr-5"
              onClick={() => this.handleAddImageBtnClick(IMG_SCREENSHOT_TYPE, "ipad")}
            >
              Tablet
            </button>
          </div>
        </div>
      )
    );
  }

  downloadAndroid(link: string, qa: false) {
    const { name } = this.props.app;
    const finalName = name + (qa ? "-qa" : "");
    const finalUrl = `${downloadAndroidUrl}?link=${encodeURIComponent(
      link,
    )}&name=${encodeURIComponent(finalName)}`;
    this.setState({ downloadingAndroid: true });
    axios
      .get(finalUrl, {
        headers: getAuthHeader(Auth.token),
        responseType: "blob",
      })
      .then((response) => {
        const { data: blob } = response;
        const objectUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", objectUrl);
        a.setAttribute("download", `${finalName}`);
        a.click();
        this.setState({ downloadingAndroid: false });
      })
      .catch(() => {
        this.setState({ downloadingAndroid: false });
      });
  }

  renderAppInstallLinksRow(QA: boolean = false) {
    const { downloadingAndroid } = this.state;
    const iosProp = "installIos" + (QA ? "QA" : "");
    const androidProp = "installAndroid" + (QA ? "QA" : "");

    return (
      Boolean(this.props.app[iosProp] || this.props.app[androidProp] || User.isAdmin()) && (
        <div className={"row install-app" + (QA ? " qa" : "")}>
          <div className="col-12">
            <dl>
              {this.props.editState && <dt>Install {QA ? "QA" : "PROD"} version:</dt>}

              <dd>
                {Boolean(this.props.app[iosProp] || User.isAdmin()) &&
                  this.renderLinkProperty(
                    iosProp,
                    (val) =>
                      Boolean(val && (this.isIos || this.isDesktop)) && (
                        <a
                          href={`itms-services://${
                            this.props.getAppState().sasToken
                          }&action=download-manifest&url=${val}`}
                        >
                          <FA name="apple" type="fab" />
                          {QA && <span>QA</span>}
                        </a>
                      ),
                    (QA ? "QA - " : "") + "iOS manifest url",
                  )}
                {Boolean(this.props.app[androidProp] || User.isAdmin()) &&
                  this.renderLinkProperty(
                    androidProp,
                    (val) =>
                      Boolean(val && (this.isAndroid || this.isDesktop)) &&
                      (!downloadingAndroid ? (
                        <div
                          className="single-button"
                          onClick={() => this.downloadAndroid(val, QA)}
                        >
                          <FA name="android" type="fab" />
                          {QA && <span>QA</span>}
                        </div>
                      ) : (
                        <div className="single-button" style={{ cursor: "default" }}>
                          <FA name="spinner" className="spin" />
                        </div>
                      )),
                    (QA ? "QA - " : "") + "Android apk url",
                  )}
              </dd>
            </dl>
          </div>
        </div>
      )
    );
  }
}
