let role = "guest";
const avaibleRoles = ["guest", "user", "admin", "mcoe user"];

export const User = {
  isAdmin: function () {
    return this.role === "admin";
  },
  isMCoEUser: function () {
    return this.role === "mcoe user";
  },
  get role() {
    return role;
  },
  set role(val) {
    if (avaibleRoles.indexOf(val) !== -1) {
      role = val;
    }
  },
};
