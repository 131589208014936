import React from "react";

import "./styles.css";
import { Header, TitleHeader } from "../Header/Header";

export function PortalNameSmall() {
  return (
    <>
      <Header text="Mobile & XR Application Portal" />
      <h4 className="portal-name-subtitle">Volvo Group Digital & IT</h4>
    </>
  );
}

export function PortalName() {
  return (
    <>
      <TitleHeader text="Mobile & XR Application Portal" />
      <h4 className="portal-name-subtitle">Volvo Group Digital & IT</h4>
    </>
  );
}
