import React from "react";
import styles from "./Spacing.module.css";

type Props = {
  children: any;
};

export function TopSpacing({ children }: Props) {
  return <div className={styles.topSpacing}>{children}</div>;
}
