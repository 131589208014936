import React from "react";

type Props = {
  label: string;
  children: any;
};
export default function AppDetailField({ label, children }: Props) {
  return (
    <div>
      <dl>
        <dt>{label}:</dt>
        <dd>{children}</dd>
      </dl>
    </div>
  );
}
