// $FlowFixMe
import React, { useState, useEffect } from "react";

import "./styles.css";

type Props = {
  avatar?: string;
  title: string;
  personName?: string;
  jobTitle: string;
  email: string;
  scaled?: boolean;
};

// $FlowFixMe
export default React.memo(function PersonCard({
  avatar = "avatar",
  title,
  personName = "",
  jobTitle,
  email,
  scaled = false,
}: Props) {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    async function getAvatar() {
      // $FlowFixMe
      const imageSrc = await import(`./cards/${avatar}.png`);
      setImageUrl(imageSrc.default);
    }
    getAvatar();
  }, [avatar]);

  const photoClassName =
    "appportal-card-photo " +
    (scaled === true ? "appportal-card-photo-scaled" : "appportal-card-photo-regular");

  return (
    <div className="appportal-card">
      {imageUrl !== "" ? (
        <div
          className={photoClassName}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      ) : null}
      <div className="appportal-card-person-info">
        <p className="appportal-card-title">{title}</p>
        <p className="appportal-card-jobtitle">
          {personName !== "" ? (
            <>
              <span className="appportal-card-personname">{personName}</span>
              {` | `}
            </>
          ) : null}
          {jobTitle}
        </p>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  );
});
