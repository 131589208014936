import React, { Component } from "react";
import ReactDOM from "react-dom";
import CreatableSelect from "react-select";
import cleanInputValue from "../../../utils/Sanitization";

type Props = {
  value?: string;
  column?: { [key: string]: any };
  options: string[];
  onCommit?: () => void;
};

type State = {
  field: any;
  cleanedField: string;
};

export default class DropDownEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { field: props.value, cleanedField: "" };
  }

  getValue() {
    return { [this.props.column?.key]: this.state.field };
  }

  getInputNode() {
    return (ReactDOM.findDOMNode(this) as HTMLInputElement).getElementsByTagName("input")[0];
  }

  handleChangeComplete = (selectedOption: any) => {
    const field = selectedOption.value;
    const cleanedField: string = cleanInputValue(field);
    try {
      if (cleanedField.length > 0) {
        this.setState({ cleanedField }, () => {
          if (this.props.onCommit) {
            this.props.onCommit();
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { options } = this.props;
    const mapper = (option: string) => {
      return {
        value: option,
        label: option,
      };
    };
    const selectOptions = options.map(mapper);
    const defaults = [this.state.field].map(mapper);

    return (
      <CreatableSelect
        defaultValue={defaults}
        options={selectOptions}
        onChange={this.handleChangeComplete}
      />
    );
  }
}
