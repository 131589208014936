import React from "react";
// @ts-ignore
import { ViewWrapper } from "airr-react";

import Main from "../../ui/Admin/Main";

export const viewName = "admin-view";

export default class Admin extends ViewWrapper {
  viewAfterActivation() {
    this.props.scrollToTop();
  }

  content() {
    return <Main />;
  }
}
