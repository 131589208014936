export const baseUrl = "/";
export const apiHost = process.env.REACT_APP_API;
export const appsDataUrl = apiHost + "apps";
export const budgetDataUrl = apiHost + "budget";
export const apiUrl = apiHost + "app";
export const checkPermUrl = apiHost + "checkperm";
export const deleteAppUrl = apiHost + "drop";
export const pictureScaleUrl = apiHost + "scale";
export const devOpsUrl = apiHost + "devops-repos";
export const downloadAndroidUrl = apiHost + "download-apk";

export const getAuthHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const Routes = {
  home: baseUrl,
  app: baseUrl + "app/:id/",
  portfolio: baseUrl + "portfolio/",
  admin: baseUrl + "admin/",
  homeTest: new RegExp("^" + baseUrl + "/{0,1}$"),
  appTest: new RegExp("^" + baseUrl + "app/([a-z-0-9]+)/{0,1}$"),
  portfolioTest: new RegExp("^" + baseUrl + "portfolio/{0,1}(\\?.*){0,1}$"),
  adminTest: new RegExp("^" + baseUrl + "admin/{0,1}$"),
};

export const iOS = "iOS";
export const Android = "Android";
export const Native = "Native";
export const AMAZON = "Fire OS";
export const EXTERNAL = "External";
export const MCOE = "MCoE";
export const SHAREPOINT =
  "https://volvogroup.sharepoint.com/sites/coll-mobile-xr/SitePages/Mobile-XR.aspx";
export const AppStatuses = ["Production", "Retired", "Pilot/POC"];
export const AppCategory = [
  "Main external apps",
  "External service and/or support apps",
  "Internal and dealer facing apps",
];
export const Languages = ["English", "Swedish", "French", "Portuguese", "German"];
export const Devices = [
  "Tablet",
  "Phone",
  "TV",
  "Watch",
  "Service and Entertainment Module",
  "Oculus Quest 2 (VR)",
  "Hololens 1 (AR)",
  "Hololens 2 (AR)",
  "HTC Vive/Pro (VR)",
  "RealWear (AR)",
  "Varjo 2/3 (VR)",
];
export const Platforms = [
  iOS,
  Android,
  "Web",
  AMAZON,
  "VR",
  "AR",
  "Projected AR",
  "Android Automotive",
];

export const ManagedBy = [MCOE, EXTERNAL];
