import { App } from "./Types";
import { AMAZON } from "./Config";
import { FiltersSelection } from "../providers/Types";

export const Util = {
  // Filters for Autocomplete fields
  filterKeys: [
    "brand",
    "region",
    "platform",
    "device",
    "teamLeader",
    "solutionLeader",
    "targetGroup",
    "BSPM",
    "businessKeyUser",
    "apmID",
    "businessProcess",
    "deliveryManager",
    "domainManager",
    "appStatus",
    "appCategory",
    "search",
    "languageSupport",
    "managedBy",
  ],
  singleFilterValues: ["apmID"],
  fetchUniqueData: function (apps: App[]) {
    const entries: { [key: string]: any } = {};

    apps.forEach((item: App) => {
      Util.filterKeys.forEach((key: string) => {
        if (!entries[key]) {
          entries[key] = {};
        }

        // @ts-ignore
        if (Array.isArray(item[key])) {
          // @ts-ignore
          item[key].forEach((value) => {
            const trimedVal = value.trim();
            entries[key][trimedVal] = trimedVal;
          });
          // @ts-ignore
        } else if (typeof item[key] === "string") {
          // @ts-ignore
          const trimedVal = item[key].trim();
          entries[key][trimedVal] = trimedVal;
          // @ts-ignore
        } else if (typeof item[key] === "number") {
          // @ts-ignore
          const val = item[key].toString();
          entries[key][val] = val;
        }
      });
    });

    return Object.entries(entries).reduce(
      (prev, entry) => {
        prev[entry[0]] = Object.values(entry[1]).filter((item) => item);
        return prev;
      },
      {} as {
        [key: string]: any;
      },
    );
  },

  getRandString: function (length: number) {
    const arr = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "r",
      "s",
      "t",
      "u",
      "w",
      "x",
      "y",
      "z",
    ];
    let string = "";

    for (let i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * arr.length + 1) - 1;
      string += arr[index];
    }

    return string;
  },

  getFAPlatformName: function (platform: string): string {
    switch (platform) {
      case "Android":
        return "android";
      case "iOS":
        return "apple";
      case "Web":
        return "chrome";
      case AMAZON:
        return "amazon";
      case "AR":
        return "ar-cardboard";
      case "VR":
        return "vr-cardboard";
      case "Projected AR":
        return "projector";
      case "Android Automotive":
        return "tachometer-alt";
      default:
        return "";
    }
  },

  getIconType: function (name: string): "fab" | "solid" | "regular" {
    switch (name) {
      case "Android":
      case "iOS":
      case "Web":
      case AMAZON:
        return "fab";
      case "Watch":
        return "regular";
      default:
        return "solid";
    }
  },

  /* getFADeviceName: function (name: string): string {
		switch (name) {
			case "Phone":
				return "mobile-alt";
			case "Tablet":
				return "tablet-alt";
			case "TV":
				return "tv";
			case "Watch":
				return "clock";
			default:
				return "";
		}
	}, */

  querySearchToObject: function (searchString: string): FiltersSelection {
    const string = searchString.replace(/^\?/, "");
    const parts = string.split("&");

    return parts.reduce((prev, curr) => {
      const items = curr.split("=");
      const [key, value] = items;

      if (items.length === 2 && Util.filterKeys.indexOf(key) > -1) {
        if (!Util.singleFilterValues.includes(key)) {
          // @ts-ignore
          prev[key] = decodeURI(value).split(",");
        }
        // @ts-ignore
        else {
          prev[key] = decodeURI(value);
        }
      }

      return prev;
    }, {} as FiltersSelection);
  },
};

export function compareTwoStrings(first: string, second: string): number {
  first = first.replace(/\s+/g, "");
  second = second.replace(/\s+/g, "");

  if (!first.length && !second.length) {
    return 1;
  }
  // if both are empty strings
  if (!first.length || !second.length) {
    return 0;
  }
  // if only one is empty string
  if (first === second) {
    return 1;
  }
  // identical
  if (first.length === 1 && second.length === 1) {
    return 0;
  }
  // both are 1-letter strings
  if (first.length < 2 || second.length < 2) {
    return 0;
  }
  // if either is a 1-letter string

  const firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}
