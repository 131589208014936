import React, { Component, SyntheticEvent } from "react";
import axios from "axios";
import { Container, Row, Col, Input, FormGroup, Label, Alert } from "reactstrap";
import Auth from "volvo-ping-auth-helper";
import { SmallActionButton, SmallActionSecondaryButton } from "../Common";
import AppFilterField from "../AppFilterField";
import { InitialState } from "../../providers/Types";
import {
  Platforms,
  devOpsUrl,
  getAuthHeader,
  ManagedBy,
  MCOE,
  EXTERNAL,
  appsDataUrl,
} from "../../resources/Config";
import { containsMobileTech } from "../../utils/Technology";
import { getDevOpsRepos } from "../../utils/Integration";
import { compareTwoStrings } from "../../resources/Util";
import ManagedByFieldDescription from "../Common/Fields/ManagedByFieldDescription";
import cleanInputValue from "../../utils/Sanitization";
import { DevOpsRepo, NewApp } from "../../resources/Types";


type Technology = "Native" | "React Native" | "Xamarin" | "Unity";

type State = {
  name: string;
  brand: string;
  region: string[];
  platorm: string[];
  includesBackend: boolean;
  managedBy: string;
  technology: Technology | undefined;
  existingDevOpsRepos: Repo[];
  devOpsRepos: DevOpsRepo[];
  appPortalNames: string[];
  similarAppNames: any[];
};

type Repo = {
  name: string;
  webUrl?: string;
};
type Props = {
  handleSaveNewApp: (app: NewApp) => void;
  closeForm: () => void;
  getAppState: () => InitialState;
};

export default class AddAppForm extends Component<Props, State> {
  state = {
    name: "",
    brand: undefined,
    region: [],
    platform: [],
    managedBy: "MCoE",
    includesBackend: false,
    technology: undefined,
    existingDevOpsRepos: [],
    devOpsRepos: [],
    appPortalNames: [],
    similarAppNames: [],
  };

  componentDidMount = () => {
    axios.get(devOpsUrl, { headers: getAuthHeader(Auth.token) }).then((results) => {
      this.setState({ existingDevOpsRepos: results.data });
    });

    // Fetch list of apps
    axios.get(appsDataUrl, { headers: getAuthHeader(Auth.token) }).then((results) => {
      this.setState({ appPortalNames: results.data.apps.map((a) => a.name) });
    });
  };

  updateListOfRepos = () => {
    const { name, platform, technology, includesBackend, managedBy } = this.state;
    let devOpsRepos = [];
    if (managedBy === MCOE) {
      devOpsRepos = getDevOpsRepos(name, platform, technology, includesBackend);
    }

    this.setState({ devOpsRepos });
  };

  checkSimilarAppNames = (name) => {
    const similarAppNames = this.state.appPortalNames.reduce((array, appName) => {
      const comparisonFactor = compareTwoStrings(appName.toLowerCase(), name.toLowerCase());
      if (comparisonFactor >= 1) {
        array.push({
          name: appName,
          factor: comparisonFactor,
        });
      }

      array.sort((a, b) => a.factor < b.factor);
      return array;
    }, []);
    this.setState({ similarAppNames });
  };

  handlePropertyChange = (property: string) => (value: string | string[]) => {
    try {
      let cleanedValue = value;
      if (this.state[property] && value && this.state[property].length < value.length) {
        cleanedValue = cleanInputValue(value);
        if (property === "region" || property === "platform") {
          cleanedValue = cleanedValue.split(",");
        }
      }
      if (cleanedValue.length > 0) {
        this.setState({ [property]: cleanedValue }, () => {
          if (property === "platform" && !containsMobileTech(this.state.platform)) {
            this.setState({ technology: undefined });
          }

          if (property === "name") {
            this.checkSimilarAppNames(this.state.name);
          }

          if (
            property === "platform" ||
            property === "technology" ||
            property === "name" ||
            property === "managedBy"
          ) {
            this.updateListOfRepos();
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSaveNewApp = () => {
    const {
      name,
      brand,
      region,
      platform,
      technology,
      devOpsRepos,
      managedBy,
      existingDevOpsRepos,
    } = this.state;
    return this.props.handleSaveNewApp({
      name,
      brand,
      region,
      platform,
      technology,
      devOpsRepos,
      managedBy,
      existingDevOpsRepos,
    });
  };

  handleIncludesBackend = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ includesBackend: !this.state.includesBackend }, () => {
      this.updateListOfRepos();
    });
  };

  handleSuggestedRepoNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const type = e.currentTarget.dataset.repoType;

    if (type && value) {
      const devOpsRepos = this.state.devOpsRepos.map((repo) => {
        if (repo.type === type) {
          return { ...repo, name: value };
        }

        return repo;
      });
      this.setState({
        devOpsRepos,
      });
    }
  };

  formDataValid = () => {
    const { name, brand, platform, technology } = this.state;
    return name === "" ||
      !brand ||
      platform.length === 0 ||
      (containsMobileTech(platform) && !technology)
      ? false
      : true;
  };

  showSuggestedRepoNames = () => {
    const { name, platform, technology, includesBackend, managedBy } = this.state;
    return name === "" ||
      (platform.length === 0 && includesBackend === false) ||
      (containsMobileTech(platform) && !technology) ||
      managedBy === EXTERNAL
      ? false
      : true;
  };

  repoAlreadyExists = (repo) => {
    return this.state.existingDevOpsRepos.some((r) => r.name === repo.name);
  };

  render() {
    const brands = this.props.getAppState()["brand"];
    const regions = this.props.getAppState()["region"];
    const platforms = Platforms;
    const technologies = ["Native", "React Native", "Xamarin", "Unity"];
    return (
      <Container>
        <Row>
          <Col lg="6" className="m-lg-auto">
            <Row>
              <Col>
                <p>App Portal Name: </p>
                <Input
                  type="text"
                  placeholder="provide app name"
                  value={this.state.name}
                  onChange={(event) => this.handlePropertyChange("name")(event.currentTarget.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AppFilterField
                  inEditState={true}
                  name="Brand"
                  selected={this.state.brand}
                  fieldType="autocomplete"
                  items={brands}
                  onChange={this.handlePropertyChange("brand")}
                >
                  <br />
                </AppFilterField>
              </Col>
            </Row>
            <Row>
              <Col>
                <AppFilterField
                  inEditState={true}
                  name="Region"
                  selected={this.state.region}
                  fieldType="multiselect"
                  items={regions}
                  onChange={this.handlePropertyChange("region")}
                >
                  <br />
                </AppFilterField>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Managed by: </p>
                <Input
                  type="select"
                  placeholder="provide type"
                  value={this.state.managedBy}
                  onChange={(event) =>
                    this.handlePropertyChange("managedBy")(event.currentTarget.value)
                  }
                >
                  {ManagedBy.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </Input>
                <ManagedByFieldDescription />
              </Col>
            </Row>
            <Row>
              <Col>
                <AppFilterField
                  inEditState={true}
                  name="Platform"
                  selected={this.state.platform}
                  fieldType="multiselect"
                  items={platforms}
                  onChange={this.handlePropertyChange("platform")}
                >
                  <br />
                </AppFilterField>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col>
                <input
                  type="checkbox"
                  id="backend"
                  checked={this.state.includesBackend}
                  onChange={this.handleIncludesBackend}
                />
                <label className="pl-2" htmlFor="backend">
                  Includes backend
                </label>
              </Col>
            </Row>
            {containsMobileTech(this.state.platform) && (
              <Row>
                <Col>
                  <p>Mobile technology: </p>
                  {technologies.map((tech) => (
                    <FormGroup check key={tech}>
                      <Input
                        type="radio"
                        name="technology"
                        value={tech}
                        checked={tech === this.state.technology}
                        onChange={(event) =>
                          this.handlePropertyChange("technology")(event.currentTarget.value)
                        }
                        id={tech}
                      />{" "}
                      <Label for={tech} check>
                        {tech}
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
            )}
            {this.showSuggestedRepoNames() && (
              <Row>
                <Col>
                  <p>Suggested repos names: </p>
                  {this.state.devOpsRepos.map((repo) => (
                    <Row key={`${repo.type}${repo.name}`} className="pb-1">
                      <Col lg="3">{repo.type}</Col>
                      <Col lg="7">
                        <Input
                          data-repo-type={repo.type}
                          type="text"
                          value={repo.name}
                          onChange={this.handleSuggestedRepoNameChange}
                        />
                      </Col>
                      {this.repoAlreadyExists(repo) === true && <Col lg="2">Exists*</Col>}
                    </Row>
                  ))}
                </Col>
              </Row>
            )}
            {this.state.similarAppNames.length > 0 && (
              <Row className="pt-3">
                <Col>
                  <Alert color="warning">
                    <p>Apps with following names already exist in AppPortal:</p>
                    <div className="mb-0">
                      {this.state.similarAppNames.map((app) => (
                        <div key={app.name}>{app.name}</div>
                      ))}
                    </div>
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="pt-3">
              <Col className="d-flex justify-content-between">
                <SmallActionSecondaryButton text="Cancel" onClick={this.props.closeForm} />

                <SmallActionButton
                  text="Confirm"
                  onClick={this.handleSaveNewApp}
                  disabled={!this.formDataValid() || this.state.similarAppNames.length > 0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
