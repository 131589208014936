import React from "react";
// @ts-ignore
import { ViewWrapper } from "airr-react";
import { Routes } from "../../resources/Config";
import {
  PortalNameSmall,
  Header,
  // AlternateHeader,
  // BorderBlueTextBox,
  Button,
  // AlternateButton,
  SearchForm,
  TopSpacing,
  McoeJumbotron,
} from "../../ui/Common";
import "../../../css/views/Home.css";
import AppManager from "../../providers/AppManager";
import cleanInputValue from "../../utils/Sanitization";

export const viewName = "home-view";

type Props = {
  appManager: AppManager;

  scrollToTop: () => void;
};

export default class Home extends ViewWrapper<Props> {
  viewAfterActivation() {
    this.props.scrollToTop();
  }

  goToPortfolio = () => {
    this.props.appManager.goTo(Routes.portfolio);
  };

  goToPortfolioFromSearch = (textSearch: string) => {
    try {
      const cleanedCode = cleanInputValue(textSearch);
      if (cleanedCode.length > 0) {
        this.props.appManager.goTo(Routes.portfolio + "?search=" + cleanedCode);
      }
    } catch (err) {
      console.log(err);
    }
  };

  content() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="home-intro">
              <div className="home-appName">
                <PortalNameSmall />
              </div>
              <div className="home-search">
                <SearchForm
                  placeholder="What are you looking for?"
                  value=""
                  onClick={this.goToPortfolioFromSearch}
                />
              </div>
            </div>

            <div className="home-tile home-explore-portfolio-ctn">
              <div className="home-deep-tile">
                <Header text="Explore our portfolio" />
                <TopSpacing>
                  <Button text="Find more" onClick={this.goToPortfolio} />
                </TopSpacing>
              </div>
            </div>
            {/* <div className="home-tile home-request-app-ctn">
							<div className="home-deep-tile">
								<AlternateHeader text="Request an application" />
								<TopSpacing>
									<AlternateButton
										text="Create request"
										onClick={this.goToContacts}
									/>
								</TopSpacing>
							</div>
						</div> */}
            {/* <div className="home-tile home-get-to-ctn">
							<div className="home-deep-tile">
								<Header text="Get to know us" />
								<BorderBlueTextBox>
									Find more information about respective Solution Leaders and our
									Services.
								</BorderBlueTextBox>
								<TopSpacing>
									<Button text="Contact" onClick={this.goToContacts} />
								</TopSpacing>
							</div>
						</div> */}
            <McoeJumbotron useSubheader={true} />
          </div>
        </div>
      </div>
    );
  }
}
