import React from "react";
import { Checkbox, Collapsible } from "../Common";

type Props = {
  title: string;
  availableOptions: string[];
  selectedOptions: string[];
  propertyName: string;
  onChange: (property: string, value: string) => any;
};

export default function FilterField({
  title,
  availableOptions,
  selectedOptions,
  propertyName,
  onChange,
}: Props) {
  return (
    <Collapsible title={title}>
      <>
        {availableOptions &&
          availableOptions.map((option) => (
            <Checkbox
              key={option}
              label={option}
              checked={selectedOptions && selectedOptions.includes(option)}
              propertyName={propertyName}
              propertyValue={option}
              toggle={onChange}
            />
          ))}
      </>
    </Collapsible>
  );
}
