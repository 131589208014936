import React from "react";
import { User } from "../../resources/User";
import { SmallButton } from "../Common";

type Props = {
  handleAddNewAppClick: () => void;
};
export default function AddNewAppBtn({ handleAddNewAppClick }: Props) {
  if (User.isAdmin()) {
    return <SmallButton text="add new app" onClick={handleAddNewAppClick} />;
  }

  return null;
}
