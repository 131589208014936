import React, { SyntheticEvent } from "react";
import { Generic } from "fp-ui-components-react";
import { Util } from "../../../resources/Util";

import styles from "./Checkbox.module.css";

type Props = {
  label: string;
  propertyName: string;
  propertyValue: string;
  checked: boolean;
  toggle: (propertyName: string, propertyValue: string) => any;
};

const FA = Generic.FontAwesome;

export function Checkbox({ label, checked, propertyName, propertyValue, toggle }: Props) {
  const onCheck = (event: SyntheticEvent<HTMLInputElement>) => {
    toggle(propertyName, propertyValue);
  };

  const guid = Util.getRandString(16);

  return (
    <div className={styles.container}>
      <input type="checkbox" value={checked} onClick={onCheck} id={guid} />
      <label className={styles.container} htmlFor={guid}>
        <span>
          <FA
            name={`${checked === true ? "check-" : ""}square`}
            type={checked === true ? "solid" : "regular"}
            className={checked === true ? styles.blue : ""}
          />
        </span>
        <span className={styles.labelText}>{label}</span>
      </label>
    </div>
  );
}
