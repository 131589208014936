import { pictureScaleUrl } from "../resources/Config";
import { App } from "../resources/Types";

export function getAppImage(app: App): string {
  const pictureUrl = app.artworks && app.artworks["512"] ? app.artworks["512"] : "";

  if (pictureUrl !== "") {
    return `${pictureScaleUrl}?url=${pictureUrl}&width=250&appId=${app.id}&key=artwork512`;
  }

  return pictureUrl;
}
