import React from "react";
import styles from "./Divider.module.css";

type Props = {
  text: string;
};

export function Divider({ text }: Props) {
  return <div className={styles.divider} />;
}

export function AccentDivider({ text }: Props) {
  return <div className={`${styles.divider} ${styles.accent}`} />;
}
