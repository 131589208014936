import { ReactNode } from "react";
import update from "immutability-helper";
// @ts-ignore
import { SceneWrapper } from "airr-react";
import Auth from "volvo-ping-auth-helper";

import HomeView, { viewName as HomeViewName } from "../views/main-scene/Home";
import PortfolioView, { viewName as PortfolioViewName } from "../views/main-scene/Portfolio";
import AppView, { viewName as AppViewName } from "../views/main-scene/AppView";
import AdminView, { viewName as AdminViewName } from "../views/main-scene/Admin";
import { Routes } from "../resources/Config";
import { APP_URL_CHANGED } from "../resources/AppEvents";

import AppManager from "../providers/AppManager";
import { FiltersSelection, InitialState } from "../providers/Types";
import { User } from "../resources/User";
import { storageKeyAdminEditOn } from "../ui/AppView/NavbarAdminMenu";
import { NewApp } from "./Viewport";

export type Props = {
  disableSidepanel: () => void;
  enableSidepanel: () => void;
  openSidepanel: () => void;
  renderAppsFilter: () => ReactNode;
  handleSaveNewApp: (app: NewApp) => void;
  getApps: () => ReactNode;
  loadNextApps: () => void;
  url: string;
  appState: InitialState;
  getAppState: () => InitialState;
  appManager: AppManager;
  dispatchClearAllFilters: () => void;
  dispatchFilterChange: (filtersSelection: FiltersSelection) => void;
  dispatchTextSearchChange: (textSearch: string) => void;
  goToApp: (appId: number) => void;
  disableGUI: () => void;
  enableGUI: () => void;
};
export default class MainScene extends SceneWrapper<Props> {
  state: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      views: [
        this.viewsConfig[HomeViewName],
        this.viewsConfig[PortfolioViewName],
        this.viewsConfig[AppViewName],
        this.viewsConfig[AdminViewName],
      ],
      activeViewName: this.getActiveViewNameFromPath(props.url),
      mayers: [],
      navbar: false,
      navbarMenu: null,
      navbarClass: null,
      backButton: true,
      navbarHeight: 64,
      GUIDisabled: false,
      //custom state properties
      dataFetched: false,
    };
  }

  registerAppEventListeners = () => {
    // @ts-ignore
    this.props.appManager.addEventListener(APP_URL_CHANGED, this.AppUrlChangeListener);
  };

  removeAppEventListeners = () => {
    // @ts-ignore
    this.props.appManager.removeEventListener(APP_URL_CHANGED, this.AppUrlChangeListener);
  };

  componentDidMount() {
    super.componentDidMount();
    this.registerAppEventListeners();
  }

  componentWillUnmount() {
    this.removeAppEventListeners();
  }

  handleEditToogle = (editOn: boolean) => {
    // @ts-ignore
    this.setState({
      views: update(this.state.views, {
        [this.state.views.findIndex((view: any) => view.props.name === AppViewName)]: {
          props: { editState: { $set: editOn } },
        },
      }),
    });
  };

  AppUrlChangeListener = ({ url }: { url: string }) => {
    if (Auth.hasIdentity()) {
      if (Routes.homeTest.test(url) && this.state.activeViewName !== HomeViewName) {
        setTimeout(() => this.changeView(HomeViewName), 0);
        return;
      } //async fix, first render view animation

      if (Routes.portfolioTest.test(url) && this.state.activeViewName !== PortfolioViewName) {
        setTimeout(() => this.changeView(PortfolioViewName), 0);
        return;
      } //async fix, first render view animation

      if (Routes.adminTest.test(url) && this.state.activeViewName !== AdminViewName) {
        setTimeout(() => this.changeView(AdminViewName), 0);
        return;
      } //async fix, first render view animation

      if (Routes.appTest.test(url)) {
        const match = Routes.appTest.exec(url);
        let id = match && match.length && match[1];

        if (typeof id === "string" && id.indexOf("new-app") !== 0) {
          //if not new app ID but standard integer ID
          id = parseInt(id, 10);
        }

        const app = this.props.getAppState().apps.find((a) => a.id === id);
        const appIndex = this.props.getAppState().apps.findIndex((a) => a.id === id);

        if (app) {
          const props = {
            app,
            appIndex,
            editState: User.isAdmin()
              ? Boolean(parseInt(localStorage.getItem(storageKeyAdminEditOn), 10))
              : false,
          };

          this.changeView(AppViewName, props);
          return;
        }
      }
    }
  };

  getActiveViewNameFromPath(path: string) {
    if (path) {
      if (Routes.portfolioTest.test(path)) {
        return PortfolioViewName;
      }

      if (Routes.appTest.test(path)) {
        return AppViewName;
      }

      if (Routes.homeTest.test(path)) {
        return HomeViewName;
      }

      if (Routes.adminTest.test(path)) {
        return AdminViewName;
      }
    }

    return HomeViewName;
  }

  viewsConfig = {
    [HomeViewName]: {
      type: HomeView,
      props: {
        appManager: this.props.appManager,
        name: HomeViewName,
        scrollToTop: this.props.scrollToTop,
      },
    },
    [PortfolioViewName]: {
      type: PortfolioView,
      props: {
        appManager: this.props.appManager,
        name: PortfolioViewName,
        handleSaveNewApp: this.props.handleSaveNewApp,
        openSidepanel: this.props.openSidepanel,
        renderAppsFilter: (extraStyle = {}) => {
          if (this.props.getAppState().renderMode === "desktop") {
            return this.props.renderAppsFilter(extraStyle);
          }

          return null;
        },
        style: { overflowY: "hidden" },
        getAppState: this.props.getAppState,
        getApps: this.props.getApps,
        loadNextApps: this.props.loadNextApps,
        handleFilterChange: this.props.handleFilterChange,
        dispatchClearAllFilters: this.props.dispatchClearAllFilters,
        dispatchTextSearchChange: this.props.dispatchTextSearchChange,
        setScrollOnBottomHandler: this.props.setScrollOnBottomHandler,
        triggerToggleShowFilters: this.props.triggerToggleShowFilters,
        goToApp: this.props.goToApp,
        scrollToTop: this.props.scrollToTop,
        getStickMenu: () => this.props.stickMenu,
        getMenuHeight: () => this.props.menuHeight,
      },
    },
    [AppViewName]: {
      type: AppView,
      props: {
        name: AppViewName,
        app: this.props.getAppState().apps[0],
        appIndex: 0,
        disableGUI: this.disableGUI,
        enableGUI: this.enableGUI,
        getAppState: this.props.getAppState,
        appManager: this.props.appManager,
        handleUpdateApp: this.props.handleUpdateApp,
        handleRevertChanges: this.props.handleRevertChanges,
        handleEditToogle: this.handleEditToogle,
        handleBackButton: this.props.handleBackButton,
        scrollToTop: this.props.scrollToTop,
      },
    },
    [AdminViewName]: {
      type: AdminView,
      props: {
        name: AdminViewName,
        getApps: this.props.getApps,
        scrollToTop: this.props.scrollToTop,
      },
    },
  };
}
