import React, { Component, SyntheticEvent } from "react";
import { Link, Consumer as AppConsumer } from "../providers/AppManager";
import { Routes, baseUrl, SHAREPOINT } from "../resources/Config";
import { User } from "../resources/User";
import Auth from "volvo-ping-auth-helper";
import { Generic } from "fp-ui-components-react";
import AppManager from "../providers/AppManager";

import { ProviderData } from "../providers/Types";

const HorizontalMenu = Generic.HorizontalMenu;
const MenuItem = Generic.HorizontalMenuItem;
const FA = Generic.FontAwesome;

type MenuLinkProps = {
  pathTo: string;
  label: string;
  currentUrl?: string;
  test: RegExp;
};

type ExternalLinkProps = {
  url: string;
  label: string;
};

function MenuLink({ pathTo, label, currentUrl, test }: MenuLinkProps) {
  return (
    <MenuItem active={test.test(currentUrl ? currentUrl : "")}>
      <Link {...{ pathTo }}>{label}</Link>
    </MenuItem>
  );
}

function ExternalLink({ url, label }: ExternalLinkProps) {
  return (
    <MenuItem>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </MenuItem>
  );
}

type Props = {
  refDOMMenu?: { current?: any };
  style?: {};
  className?: string;
  getFiltersSelectionUrlSuffix: () => string;
};

export default class Menu extends Component<Props> {
  handleLogout = (e: SyntheticEvent<any>, appManager?: AppManager) => {
    e.preventDefault();
    Auth.hardLogout();
    appManager && appManager.goTo(baseUrl, true);
  };

  render() {
    return (
      <AppConsumer>
        {({ appState: { url }, appManager }: ProviderData) => (
          <HorizontalMenu
            refDOM={this.props.refDOMMenu}
            style={this.props.style}
            className={this.props.className}
            centered={true}
          >
            <MenuLink pathTo={Routes.home} test={Routes.homeTest} label="home" currentUrl={url} />
            <MenuLink
              pathTo={Routes.portfolio + this.props.getFiltersSelectionUrlSuffix()}
              test={Routes.portfolioTest}
              label="portfolio"
              currentUrl={url}
            />
            <ExternalLink url={SHAREPOINT} label="about us" />

            {User.isAdmin() && (
              <MenuLink
                pathTo={Routes.admin}
                test={Routes.adminTest}
                label="admin"
                currentUrl={url}
              />
            )}
            <MenuItem className="toRight">
              <a href="#logout" onClick={(e) => this.handleLogout(e, appManager)}>
                <span className="mr-1"> logout</span>
                <FA name="sign-out-alt" />
              </a>
            </MenuItem>
          </HorizontalMenu>
        )}
      </AppConsumer>
    );
  }
}
