import React, { Component, ReactNode } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styles from "./Modal.module.css";

type Size = "xl" | "lg" | "sm";

type Props = {
  title: string;
  size: Size | undefined;
  children: ReactNode;
  isOpen: boolean;
};

export default class AppPortalModal extends Component<Props> {
  render() {
    const { title, children, size, isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} size={size}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody className={styles.container}>{children}</ModalBody>
      </Modal>
    );
  }
}
