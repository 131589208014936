import React from "react";
import { ReactNode } from "react";
import cleanInputValue from "../utils/Sanitization";

type Props = {
  inEditState: boolean;
  placeholder: string;
  inputType?: string;
  value: string | string[];
  onChange: (val: string) => void;
  render?: (selected: string | string[]) => ReactNode;
  children: (selected: string | string[]) => ReactNode;
  tagType?: string;
  extraClass?: string;
};

const cleanChange = (eventValue: string, pastValue: string | string[]) => {
  const oldValue = pastValue;
  let cleanedCode = eventValue;
  try {
    if (oldValue.length < eventValue.length) {
      cleanedCode = cleanInputValue(eventValue);
    }

    return cleanedCode;
  } catch (err) {
    console.log(err);
  }
  return "";
};

export default function AppInputField({
  inEditState,
  placeholder,
  inputType = "text",
  value,
  onChange,
  render,
  children,
  tagType,
  extraClass,
}: Props) {
  if (inEditState) {
    const props = {
      className: "form-control " + (typeof extraClass === "string" ? extraClass : ""),
      placeholder,
      value,
      onChange: (e) => onChange(cleanChange(e.currentTarget.value, value)),
      type: tagType,
    };

    if (!tagType && inputType) {
      props.type = inputType;
    }

    return React.createElement(tagType ? tagType : "input", props);
  } else {
    return typeof render === "function"
      ? render(value)
      : typeof children === "function"
        ? children(value)
        : children;
  }
}
