import React from "react";

import styles from "../Utils.module.css";

type Props = {
  devices: string[];
};

export default function Devices({ devices }: Props) {
  return (
    <div className={styles.devices}>
      <div className={styles.title}>Device</div>
      <div>{Array.isArray(devices) && devices.join(", ")}</div>
    </div>
  );
}
