import React from "react";
import Ratings from "react-ratings-declarative";
import styles from "../Utils.module.css";
import { Generic } from "fp-ui-components-react";
const FA = Generic.FontAwesome;

type Props = {
  rating: number;
  count: number;
};

export default function Rating({ rating, count }: Props) {
  if (!rating) {
    rating = 0;
  }

  return (
    <div className={styles.rating} title="Average Store rating">
      <Ratings
        rating={rating}
        widgetRatedColors={returnRatingColor(rating)}
        widgetDimensions="12px"
        widgetSpacings="3px"
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
      <div>
        <FA style={{ paddingLeft: "10px", paddingRight: "5px" }} name={"user"} />
        {count}
      </div>
    </div>
  );
}

function returnRatingColor(rating) {
  // material colors, red, yellow, green
  if (rating <= 2) {
    return "#b23939";
  } else if (rating > 2 && rating <= 4) {
    return "#b28900";
  } else {
    return "#00a152";
  }
}
