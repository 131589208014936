import React from "react";
import styles from "./Header.module.css";

type Props = {
  text: string;
};

export function Header({ text }: Props) {
  return <h1 className={styles.header}>{text}</h1>;
}

export function TitleHeader({ text }: Props) {
  return <h1 className={`${styles.header} ${styles.titleHeader}`}>{text}</h1>;
}

export function AlternateHeader({ text }: Props) {
  return <h1 className={`${styles.header} ${styles.alternate}`}>{text}</h1>;
}

export function AlternateSubheader({ text }: Props) {
  return <h2 className={`${styles.subHeader} ${styles.alternate}`}>{text}</h2>;
}

export function SectionHeader({ text }: Props) {
  return <h3 className={styles.section}>{text}</h3>;
}
